import * as React from 'react';
import axios from 'axios';

import { styled, createTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';

import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import EditableLabel from '../UI/EditableLabel';
import EditableSelect from '../UI/EditableSelect';
import EditableDate from '../UI/EditableDate';
import EditableProgressbar from '../UI/EditableProgressbar';
import SearchBar from '../UI/SearchBar';
import PaginatedTable from '../UI/PaginatedTable';

import { blue, blueGrey, green, red } from '@mui/material/colors';

import { config } from '../config';
import { getRole, getClient } from '../helpers/login';

const debugOptions = [
  { id: 1, nome: 'Opzione 1' },
  { id: 2, nome: 'Opzione 2' },
  { id: 3, nome: 'Opzione 3' },
  { id: 4, nome: 'Opzione 4' },
  { id: 5, nome: 'Opzione 5' },
]

const borderColor = blueGrey[50]
const borderWidth = '2px'

const customTheme = createTheme({
  tableHead: {
    applicabilita: blueGrey['A700'],
    adempimenti: blueGrey[500],
    valutazione: blueGrey[400],
    compliance: green[500]
  },
  checkbox: {
    applicabilita: blueGrey['A700'],
    adempimenti: blueGrey[500],
    valutazione: blueGrey[400],
    compliance: green[500]
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: customTheme.tableHead.applicabilita,
    color: theme.palette.common.white,
    borderRight: `${borderWidth} solid ${borderColor}`,
    fontSize: 17,
    fontWeight: 'bolder'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderRight: `${borderWidth} solid ${borderColor}`
  },
}));

const StyledTableCellAdempimenti = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: customTheme.tableHead.adempimenti,
    color: theme.palette.common.white,
    borderRight: `${borderWidth} solid ${borderColor}`,
    fontSize: 17,
    fontWeight: 'bolder'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderRight: `${borderWidth} solid ${borderColor}`
  },
}));

const StyledTableCellValutazione = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: customTheme.tableHead.valutazione,
    color: theme.palette.common.white,
    borderRight: `${borderWidth} solid ${borderColor}`,
    fontSize: 17,
    fontWeight: 'bolder'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderRight: `${borderWidth} solid ${borderColor}`
  },
}));

const StyledTableCellCompliance = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: customTheme.tableHead.compliance,
    color: theme.palette.common.white,
    borderRight: `${borderWidth} solid ${borderColor}`,
    fontSize: 17,
    fontWeight: 'bolder'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderRight: `${borderWidth} solid ${borderColor}`
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    borderBottom: 0,
  },
}));

const StyledTableCellArticle = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRowArticle = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function isAbleToManageRow(userRole) {
  switch(userRole) {
    case -1: return true
    case 1: return true
    case 2: return true
    case 4: return true
    default: return false
  }
}

let loadingAssessments = false
let userRole = null
let userClient = null

export default function AssesmentManagment(props) {

  let legalId = props.legalId
  let clientId = props.clientId
  if(legalId !== null) window.sessionStorage.setItem('legalId', legalId)
  else legalId = window.sessionStorage.getItem('legalId')
  if(clientId !== null) window.sessionStorage.setItem('clientId', clientId)
  else clientId = window.sessionStorage.getItem('clientId')

  userRole = getRole()
  userClient = getClient()

  const [showAdempimenti, setShowAdempimenti] = React.useState(true)
  const [showValutazione, setShowValutazione] = React.useState(true)
  const [showCompliance, setShowCompliance] = React.useState(true)

  const [openText, setOpenText] = React.useState(false);
  const [modalText, setModalText] = React.useState("");

  const handleOpenText = () => {
    setOpenText(true);
  };

  const handleCloseText = () => {
    setOpenText(false);
  };

  const [rows, setRows] = React.useState([])
  const [assessments, setAssessments] = React.useState([])
  const [articles, setArticles] = React.useState([])
  const [args, setArguments] = React.useState([])
  const [owners, setOwners] = React.useState([])
  const [filter, setFilter] = React.useState('')

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
      setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
  };

  function getAssessments() {
    if(loadingAssessments === false) {
      console.log('Checking for assessments...')
      loadingAssessments = true

      fetch(`${config.API_ASSESSMENTS}/getAssessments.php`, {
        method: 'POST',
        body: JSON.stringify({
            clientId: clientId,
            legalId: legalId
        }),
      })
      .then(response => response.json())
      .then(data => {
          console.log('Assessments received:', data);
          setAssessments( data )
          let tmp = []
          for(let t of data) {
            if( !isArticleIn(t, tmp)) tmp.push(t)
          }
          tmp.sort(compareFn)
          setArticles( tmp )
          loadingAssessments = false
      })
    }
  }

  React.useEffect(() => {
    if(filter !== '') {
      let tmp = []
      let filtered = assessments.filter((el) => {
        return String(el.testo_articolo).toLowerCase().includes(filter.toLowerCase()) || String(el.testo_comma).toLowerCase().includes(filter.toLowerCase())
      })
      for(let t of filtered) {
        if( !isArticleIn(t, tmp)) tmp.push(t)
      }
      tmp.sort(compareFn)
      setArticles( tmp )
    } else {
      let tmp = []
      for(let t of assessments) {
        if( !isArticleIn(t, tmp)) tmp.push(t)
      }
      tmp.sort(compareFn)
      setArticles( tmp )
    }
  }, [filter])

  function isArticleIn(art, articles) {
    for(let t of articles) {
      if(t.id_articolo === art.id_articolo) return true
    }
    return false
  }

  function getArguments() {
    axios.post(`${config.API_CHOICES}/arguments/getArguments.php`)
          .then((response) => {
              console.log('Loading arguments', response.data)
              setArguments(response.data);
          });
  }

  React.useEffect(() => {
    if(assessments.length === 0) getAssessments()
    if(args.length === 0) getArguments()
    if(owners.length === 0) getOwners()
  }, [])
  
  const hanldeSelectArticle = (id) => {
    let art = articles.find((el) => el.id === id)
    let tmp = []
    for (let t of assessments) {
      if(t.id_articolo === art.id_articolo) tmp.push(t)
    }
    setRows( tmp )
  }

  const handleSaveChanges = (id, data, column) => {
    console.log(`Saving changes (${id}):`, column, '=>', data)
    let newRows = [...rows]
    for(let i=0; i<newRows.length; i++) { if( newRows[i].id === id ) newRows[i][column] = data}
    console.log(newRows)
    setRows(newRows)
    console.log('Row saved')

    fetch(`${config.API_ASSESSMENTS}/modifyAssessment.php`, {
      method: 'POST',
      body: JSON.stringify({
        id: id,
        column: column,
        value: data
      }),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Post result:', data);
      if ( !data ) alert('Errore durante la modifica. Aggiornare la pagina e riprovare')
    })

    console.log('Row saved')

  }

  const handleSelectDocument = (e) => {
    console.log('Select file to upload')
    document.getElementById(`evd-${e.target.id}`).click()
  }

  const handleUploadDocument = (event, id) => {
    let file = event.target.files[0]
    console.log('Uploading file', file)
    
    let data = new FormData()
    data.append('id', id)
    data.append('file', file)

    fetch(`${config.API_ASSESSMENTS}/docs/uploadDocument.php`, {
      method: 'POST',
      body: data
    })
    .then(response => response.json())
    .then(data => {
      console.log('Post result:', data);
      if ( !data ) alert('Errore durante l\'upload. Aggiornare la pagina e riprovare')
      // else getAssessments()
    })

  }

  const handleDeleteDocument = (id, documento) => {
    console.log('Deleting document', documento)

    fetch(`${config.API_ASSESSMENTS}/docs/deleteDocument.php`, {
      method: 'POST',
      body: JSON.stringify({
        id: id
      }),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Post result:', data);
      if ( !data ) alert('Errore durante la modifica. Aggiornare la pagina e riprovare')
      // else getAssessments()
    })
  }

  function getOwners() {
    fetch(`${config.API_CHOICES}/owners/getOwners.php`, {
      method: 'POST',
      body: JSON.stringify({
        cliente: clientId
      }),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Loading owners', data)
      setOwners(data);
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} md={12} lg={12}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <FormControlLabel 
            control={
              <Checkbox 
                onChange={(e) => setShowAdempimenti(e.target.checked)} 
                defaultChecked 
                sx={{
                  color: customTheme.checkbox.adempimenti,
                  '&.Mui-checked': {
                    color: customTheme.checkbox.adempimenti,
                  },
                }}
              />
            } 
            label={
              <Typography 
                variant="h5" 
                color={customTheme.checkbox.adempimenti}
                sx={{ fontWeight: 'bold' }}
              >Elenco adempimenti</Typography>
            }
          />
          <FormControlLabel 
            control={
              <Checkbox 
                onChange={(e) => setShowValutazione(e.target.checked)} 
                defaultChecked 
                sx={{
                  color: customTheme.checkbox.valutazione,
                  '&.Mui-checked': {
                    color: customTheme.checkbox.valutazione,
                  },
                }}
              />
            } 
            label={
              <Typography 
                variant="h5" 
                color={customTheme.checkbox.valutazione}
                sx={{ fontWeight: 'bold' }}
              >Valutazione di impatto</Typography>
            }
          />
          <FormControlLabel 
            control={
              <Checkbox 
                onChange={(e) => setShowCompliance(e.target.checked)} 
                defaultChecked 
                sx={{
                  color: customTheme.checkbox.compliance,
                  '&.Mui-checked': {
                    color: customTheme.checkbox.compliance,
                  },
                }}
              />
            } 
            label={
              <Typography 
                variant="h5"
                color={customTheme.checkbox.compliance}
                sx={{ fontWeight: 'bold' }}
              >Compliance</Typography>
            }
          />
        </Stack>
        <SearchBar
          value={filter}
          onChange={(newValue) => setFilter(newValue) }
          onCancelSearch={() => setFilter('') }
        />
      </Grid>
      <Grid item sm={12} md={3} lg={3}>
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCellArticle align='center' justify="center">
                          Articoli
                        </StyledTableCellArticle>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {(rowsPerPage > 0
                        ? articles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : articles
                    ).map((article) => (

                        <StyledTableRowArticle key={article.id}>
                            <StyledTableCellArticle 
                              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}}
                              onClick={() => hanldeSelectArticle(article.id)}
                            >
                              {article.articolo}
                            </StyledTableCellArticle>
                        </StyledTableRowArticle>
                        
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[15, 30, 60, { label: 'All', value: -1 }]}
                            colSpan={3}
                            count={articles.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'articles per page',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
      </Grid>
      <Grid item sm={12} md={9} lg={9}>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: window.screen.height - 300}}>
            <Table 
              sx={{ minWidth: 700 }} 
              aria-label="customized table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Applicabilità</StyledTableCell>
                  <StyledTableCellAdempimenti>Articolo</StyledTableCellAdempimenti>
                  <StyledTableCellAdempimenti>Comma</StyledTableCellAdempimenti>
                  { (showAdempimenti === true || showAdempimenti === 1) &&
                    <>
                      <StyledTableCellAdempimenti align="center">Argomento</StyledTableCellAdempimenti>
                      <StyledTableCellAdempimenti align="center">Descrizione adempimento</StyledTableCellAdempimenti>
                    </>
                  }
                  { (showValutazione === true || showValutazione === 1) &&
                    <>
                      <StyledTableCellValutazione align="center">Rischio sanzionatorio</StyledTableCellValutazione>
                      <StyledTableCellValutazione align="center">Aree interessate</StyledTableCellValutazione>
                      <StyledTableCellValutazione align="center">Impatto</StyledTableCellValutazione>
                      <StyledTableCellValutazione align="center">Valutazione impatto</StyledTableCellValutazione>
                      <StyledTableCellValutazione align="center">Scadenza</StyledTableCellValutazione>
                    </>
                  }
                  { (showCompliance === true || showCompliance === 1) &&
                    <>
                      <StyledTableCellCompliance align="center">Grado di valutazione</StyledTableCellCompliance>
                      <StyledTableCellCompliance align="center">Motivo valutazione</StyledTableCellCompliance>
                      <StyledTableCellCompliance align="center">Stato di conformità</StyledTableCellCompliance>
                      <StyledTableCellCompliance align="center">Data raggiungimento conformità</StyledTableCellCompliance>
                      <StyledTableCellCompliance align="center">Responsabile</StyledTableCellCompliance>
                      <StyledTableCellCompliance align="center">Evidenze</StyledTableCellCompliance>
                      <StyledTableCellCompliance align="center">Periodicità di controllo (mesi)</StyledTableCellCompliance>
                      <StyledTableCellCompliance align="center">Prossimo controllo</StyledTableCellCompliance>
                    </>
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {

                  let commaColor = filter !== '' && String(row.testo_comma).toLowerCase().includes(filter.toLowerCase()) ? red[500] : blue[900]

                  let el =
                  <StyledTableRow key={row.id}>
                    <StyledTableCell align="center">
                      <Checkbox 
                        checked={row.applicabilita} 
                        onChange={ event => handleSaveChanges(row.id, event.target.checked, 'applicabilita') }
                        disabled={!isAbleToManageRow(userRole)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: 'bolder', fontSize: '22px', color: blue[900]}}>
                      <Button
                        onClick={() => {
                          setModalText(row.testo_articolo)
                          setOpenText(true)
                        }}
                      >{row.articolo}</Button>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: 'bolder', fontSize: '22px', color: commaColor}}>
                    <Button
                        onClick={() => {
                          setModalText(row.testo_comma)
                          setOpenText(true)
                        }}
                      >{row.comma}</Button>
                    </StyledTableCell>
                    { (showAdempimenti === true || showAdempimenti === 1) &&
                      <>
                        <StyledTableCell align="center">
                          <EditableSelect
                            style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem'}}
                            initialValue={row.argomento}
                            onBlur={ value => handleSaveChanges(row.id, value, 'argomento') }
                            options={args}
                            editable={isAbleToManageRow(userRole)}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <EditableLabel
                            style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem'}}
                            initialValue={row.descrizione}
                            onBlur={ value => handleSaveChanges(row.id, value, 'descrizione') }
                            editable={isAbleToManageRow(userRole)}
                          />
                        </StyledTableCell>
                      </>
                    }
                    { (row.applicabilita === 1  || row.applicabilita === true) &&
                      <>
                        { (showValutazione === true || showValutazione === 1) &&
                          <>
                            <StyledTableCell align="center">
                              <EditableLabel
                                style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem'}}
                                initialValue={row.rischio}
                                onBlur={ value => handleSaveChanges(row.id, value, 'rischio') }
                                number
                                min={0}
                                max={10}
                                editable={isAbleToManageRow(userRole)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <EditableLabel
                                style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem'}}
                                initialValue={row.aree}
                                onBlur={ value => handleSaveChanges(row.id, value, 'aree') }
                                editable={isAbleToManageRow(userRole)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <EditableLabel
                                style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem'}}
                                initialValue={row.impatto}
                                onBlur={ value => handleSaveChanges(row.id, value, 'impatto') }
                                editable={isAbleToManageRow(userRole)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <EditableLabel
                                style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem'}}
                                initialValue={row.valutazione}
                                onBlur={ value => handleSaveChanges(row.id, value, 'valutazione') }
                                number
                                min={1}
                                max={10}
                                editable={isAbleToManageRow(userRole)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <EditableDate 
                                style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem'}}
                                initialValue={row.scadenza}
                                onBlur={ value => handleSaveChanges(row.id, value, 'scadenza') }
                                editable={isAbleToManageRow(userRole)}
                              />
                            </StyledTableCell>
                          </>
                        }
                        { (showCompliance === true || showCompliance === 1) &&
                          <>
                            <StyledTableCell align="center">
                              <EditableProgressbar
                                style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem'}}
                                initialValue={row.grado}
                                onBlur={ value => handleSaveChanges(row.id, value, 'grado') }
                                editable={isAbleToManageRow(userRole)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <EditableLabel
                                style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem'}}
                                initialValue={row.motivo}
                                onBlur={ value => handleSaveChanges(row.id, value, 'motivo') }
                                editable={isAbleToManageRow(userRole)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <EditableSelect
                                style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem'}}
                                initialValue={row.stato}
                                onBlur={ value => handleSaveChanges(row.id, value, 'stato') }
                                options={[
                                  { id: 1, nome: 'Non conforme' },
                                  { id: 2, nome: 'In gestione' },
                                  { id: 3, nome: 'Conforme' }
                                ]}
                                editable={isAbleToManageRow(userRole)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <EditableDate 
                                style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem'}}
                                initialValue={row.raggiungimento}
                                onBlur={ value => handleSaveChanges(row.id, value, 'raggiungimento') }
                                editable={isAbleToManageRow(userRole)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <EditableSelect
                                style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem'}}
                                initialValue={row.responsabile}
                                onBlur={ value => handleSaveChanges(row.id, value, 'responsabile') }
                                options={owners}
                                editable={isAbleToManageRow(userRole)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                            {
                              row.evidenze === null
                              ?
                                  <IconButton color="success">
                                      <AddIcon
                                        id={row.id}
                                        onClick={handleSelectDocument}
                                      />
                                      <input
                                        type="file"
                                        id={`evd-${row.id}`}
                                        onChange={ (e) => handleUploadDocument(e, row.id) }
                                        hidden
                                      />
                                    </IconButton>
                              :
                              <>
                                {/*<IconButton color="secondary">
                                  <VisibilityIcon 
                                    onClick={() => {
                                      let a = document.createElement('a')
                                      a.href = `${config.API_ASSESSMENTS}/${row.id}`
                                      a.target = "_blank"
                                      a.click()
                                    }}
                                  />
                                </IconButton>*/}
                                <IconButton color="success">
                                  <DownloadIcon 
                                    onClick={() => {
                                      let a = document.createElement('a')
                                      a.href = `${config.API_ASSESSMENTS}/docs/downloadDocument.php?id=${row.id}`
                                      a.target = "_blank"
                                      a.click()
                                    }}
                                  />
                                </IconButton>
                                {/*<IconButton color="primary">
                                  <EditIcon 

                                  />
                                </IconButton>*/}
                                <IconButton color="error">
                                  <DeleteIcon 
                                    onClick={ () => handleDeleteDocument(row.id, row.evidenze) }
                                  />
                                </IconButton>
                              </>
                            }
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <EditableLabel
                                style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem'}}
                                initialValue={row.periodicita}
                                onBlur={ value => handleSaveChanges(row.id, value, 'periodicita') }
                                number
                                min={1}
                                editable={isAbleToManageRow(userRole)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <EditableDate 
                                style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem'}}
                                initialValue={row.controllo}
                                onBlur={ value => handleSaveChanges(row.id, value, 'controllo') }
                                editable={isAbleToManageRow(userRole)}
                              />
                            </StyledTableCell>
                          </>
                        }
                      </>
                    }
                  </StyledTableRow>

                  /*return (
                    filter !== ''
                      ? ( row.testo_articolo.toLowerCase().includes(filter.toLowerCase()) || row.testo_comma.toLowerCase().includes(filter.toLowerCase()) ) && el
                      : el
                  )*/

                  return el

                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <TextDialog 
          open={openText}
          handleClose={handleCloseText}

          text={modalText}
        />
      </Grid>
    </Grid>
  );
}

function TextDialog(props) {

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.text}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      {/*<IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>*/}
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      {/*<IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>*/}
    </Box>
  );
}

function compareFn(a, b) {

  a = a.articolo
  b = b.articolo

  if( typeof a === 'number' && typeof b === 'number') {
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    }
  }

  const suff = {
    bis: 1, 
    ter: 2
  }

  let numA = null
  let numB = null

  a = a.toString()
  b = b.toString()

 
  let searchNumberA = a.match(/\d+/g)
  if (searchNumberA === null) return 1
  numA = parseInt(searchNumberA.length > 0 ? searchNumberA[0] : '0')
  let searchNumberB = b.match(/\d+/g)
  if (searchNumberB === null) return -1
  numB = parseInt(searchNumberB.length > 0 ? searchNumberB[0] : '0')

  const nameA = a.toLowerCase();
  const nameB = b.toLowerCase();

  let suffA = 0
  let suffB = 0
  for (const [key, value] of Object.entries(suff)) {
    if(nameA.includes(key)) suffA = value
    if(nameB.includes(key)) suffB = value
  }

  if (numA < numB) {
    return -1;
  } else if (numA > numB) {
    return 1;
  }

  if (suffA < suffB) {
    return -1;
  }
  if (suffA > suffB) {
    return 1;
  }

  return 0;

}