import * as React from 'react';
import axios from "axios";


import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import EditableLabel from '../UI/EditableLabel';
import EditableSelect from '../UI/EditableSelect';
import SearchBar from '../UI/SearchBar'


import Grid from '@mui/material/Grid';

import PaginatedTable from '../UI/PaginatedTable';

import { getRole, getClient } from '../helpers/login';
import { config } from '../config';

let userRole = null;
let userClient = null;

export default function ChoiceTables(props) {

    userRole = getRole()
    userClient = getClient()

    const [scopes, setScopes] = React.useState([])
    const [acts, setActs] = React.useState([])
    const [args, setArguments] = React.useState([])
    const [owners, setOwners] = React.useState([])

    function getScopes() {
        axios.post(`${config.API_CHOICES}/scopes/getScopes.php`)
            .then((response) => {
                console.log (response.data)
                setScopes( response.data );
            });
    }

    function getActs() {
        axios.post(`${config.API_CHOICES}/acts/getActs.php`)
            .then((response) => {
                console.log (response.data)
                setActs( response.data );
            });
    }

    function getArguments() {
        axios.post(`${config.API_CHOICES}/arguments/getArguments.php`)
            .then((response) => {
                console.log (response.data)
                setArguments( response.data );
            });
    }

    function getOwners() {
        fetch(`${config.API_CHOICES}/owners/getOwners.php`, {
            method: 'POST',
            body: JSON.stringify({
                cliente: userClient,
                ruolo: userRole
            }),
        })
        .then(response => response.json())
        .then(data => {
            console.log (data)
            setOwners( data );
        })
    }

    React.useEffect(() => {
        getScopes()
        getActs()
        getArguments()
        getOwners()
    }, [])

    return(
        <Grid container spacing={2} sx={{ border: 0}}>
            <Grid item md={6} sm={12}>
                <PaginatedTable
                    title="Ambito"
                    rows={scopes}
                    perPage={15}
                    rowsPerPageOptions={[15, 30, 45, { label: 'All', value: -1 }]}

                    addEndpoint={`${config.API_CHOICES}/scopes/addScope.php`}
                    modifyEndpoint={`${config.API_CHOICES}/scopes/modifyScope.php`}
                    deleteEndpoint={`${config.API_CHOICES}/scopes/deleteScope.php`}

                    reloadRows={getScopes}

                    editable={true}
                />
            </Grid>

            <Grid item md={6} sm={12}>
                <PaginatedTable
                    title="Tipologia atto"
                    rows={acts}
                    perPage={15}
                    rowsPerPageOptions={[15, 30, 45, { label: 'All', value: -1 }]}

                    addEndpoint={`${config.API_CHOICES}/acts/addAct.php`}
                    modifyEndpoint={`${config.API_CHOICES}/acts/modifyAct.php`}
                    deleteEndpoint={`${config.API_CHOICES}/acts/deleteAct.php`}

                    reloadRows={getActs}

                    editable={true}
                />
            </Grid>
            
            <Grid item md={6} sm={12}>
                <PaginatedTable
                    title="Argomenti"
                    rows={args}
                    perPage={15}
                    rowsPerPageOptions={[15, 30, 45, { label: 'All', value: -1 }]}

                    addEndpoint={`${config.API_CHOICES}/arguments/addArgument.php`}
                    modifyEndpoint={`${config.API_CHOICES}/arguments/modifyArgument.php`}
                    deleteEndpoint={`${config.API_CHOICES}/arguments/deleteArgument.php`}

                    reloadRows={getArguments}

                    editable={true}
                />
            </Grid>

            <Grid item md={6} sm={12}>
                <OwnersTable
                    title="Responsabili"
                    rows={owners}
                    perPage={15}
                    rowsPerPageOptions={[15, 30, 45, { label: 'All', value: -1 }]}

                    addEndpoint={`${config.API_CHOICES}/owners/addOwner.php`}
                    modifyEndpoint={`${config.API_CHOICES}/owners/modifyOwner.php`}
                    deleteEndpoint={`${config.API_CHOICES}/owners/deleteOwner.php`}

                    reloadRows={getOwners}

                    editable={true}
                />
            </Grid>

        </Grid>
    )

}

function OwnersTable(props) {

    function createBlankRow() {
        return {
            id: 0,
            nome: '',
            cliente: userRole == -1 || userRole == 1 ? 0 : userClient
        }
    }

    const [rows, setRows] = React.useState(props.rows)
    const [isAddingRow, setIsAddingRow] = React.useState(false)

    React.useEffect(() => {
        setRows(props.rows)
    }, [props.rows])

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.perPage !== undefined ? props.perPage : props.rowsPerPageOptions[0]);

    const [filter, setFilter] = React.useState('')

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
    }));
      
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
    }));

    const handleCreateNewRow = () => {
        let newRows = [...rows]
        newRows.push(createBlankRow())
        setRows(newRows)
        setIsAddingRow(true)
        let nPages = rows.length%rowsPerPage === 0 ? ( rows.length / rowsPerPage ) + 1 : Math.ceil(rows.length / rowsPerPage)
        setPage(nPages-1)
    }
    
    const handleDiscardNewRow = () => {
        let newRows = [...rows]
        newRows.pop()
        setRows(newRows)
        setIsAddingRow(false)
        setPage(0)
    }

    const handleSaveNewRow = () => {
        let newRow = rows.find((el) => { return el.id === 0 } )
        delete newRow.id
        console.log('Saving new user', newRow)
        setIsAddingRow(false)
    
        fetch(props.addEndpoint, {
            method: 'POST',
            body: JSON.stringify(newRow),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Post result:', data);
            if ( !data ) alert('Errore durante la modifica. Aggiornare la pagina e riprovare')
            else props.reloadRows()
        })
    
    }

    const handleSaveChanges = (id, data) => {
        console.log(`Saving changes (${id}):`, data)
        let newRows = [...rows]
        for(let i=0; i<newRows.length; i++) { if( newRows[i].id === id ) newRows[i]['nome'] = data}
        console.log(newRows)
        setRows(newRows)

        fetch(props.modifyEndpoint, {
            method: 'POST',
            body: JSON.stringify({
                id: id,
                value: data
            }),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Post result:', data);
            if ( !data ) alert('Errore durante la modifica. Aggiornare la pagina e riprovare')
        })

        console.log('Row saved')
    }

    const handleDeleteRow = (id) => {
        console.log('Deleting row', id)
  
        fetch(props.deleteEndpoint, {
          method: 'POST',
          body: JSON.stringify({
            id: id
          }),
        })
        .then(response => response.json())
        .then(data => {
          console.log('Post result:', data);
          if ( !data ) alert('Errore durante la modifica. Aggiornare la pagina e riprovare')
          else props.reloadRows()
        })
    }

    const handleSaveChangesNewRow = (data, column) => {
        let newRows = [...rows]
        newRows.find((el) => { return el.id === 0 })[column] = data
        setRows(newRows)
        console.log('New row changed')
    }

    const [clients, setClients] = React.useState([])

    function getClients() {
        axios.post(`${config.API_USERS}/clients/getClients.php`)
            .then((response) => {
                console.log (response.data)
                setClients( response.data );
            });
    }

    React.useEffect(() => {
        getClients()
    }, [])
      
    return (
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell align='center' justify="center">
                            {
                                isAddingRow === false
                                ?
                                <AddCircleOutlineIcon
                                    sx={{ cursor: 'pointer', float: 'left' }}
                                    onClick={handleCreateNewRow}
                                />
                                :
                                <RemoveCircleOutlineIcon
                                    sx={{ cursor: 'pointer', float: 'left' }}
                                    onClick={handleDiscardNewRow}
                                />
                            }
                            {
                                props.title
                            }
                            {
                                isAddingRow === false
                                ?
                                <AddCircleOutlineIcon
                                    sx={{ cursor: 'pointer', float: 'right' }}
                                    onClick={handleCreateNewRow}
                                />
                                :
                                <RemoveCircleOutlineIcon
                                    sx={{ cursor: 'pointer', float: 'right' }}
                                    onClick={handleDiscardNewRow}
                                />
                            }
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    <SearchBar
                        value={filter}
                        onChange={(newValue) => setFilter(newValue) }
                        onCancelSearch={() => setFilter('') }
                    />

                    {(rowsPerPage > 0
                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : rows
                    ).map((row) => {

                        const el = 
                        <StyledTableRow key={row.id}>
                            <StyledTableCell sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <EditableLabel
                                    style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem'}}
                                    initialValue={row.nome}
                                    onBlur={value => { 
                                        if(isAddingRow === false || row.id !== 0) { handleSaveChanges(row.id, value) }
                                        else { handleSaveChangesNewRow(value, 'nome') }  
                                    }}
                                    editable={props.editable}
                                />
                                { (userRole == -1 || userRole == 1) &&
                                    <EditableSelect
                                        style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem', marginLeft: 'auto'}}
                                        initialValue={row.cliente}
                                        onBlur={value => { 
                                            if(isAddingRow === false || row.id !== 0) { handleSaveChanges(row.id, value, 'cliente') }
                                            else { handleSaveChangesNewRow(value, 'cliente') }  
                                        }}
                                        options={clients}
                                        editable={isAddingRow && row.id === 0}
                                    />
                                }
                                <Stack sx={{ marginLeft: 'auto' }} direction="row" spacing={6}>
                                    {
                                        row.id !== 0
                                        ?
                                        <IconButton>
                                            <DeleteIcon
                                                sx={{ cursor: 'pointer', color: 'red' }}
                                                onClick={() => handleDeleteRow(row.id)}
                                            />
                                        </IconButton>
                                        :
                                        <IconButton>
                                            <SaveIcon
                                                sx={{ cursor: 'pointer', color: 'green' }}
                                                onClick={handleSaveNewRow}
                                            />
                                        </IconButton>
                                    }
                                    {   props.handleRowLink !== undefined &&
                                        <ArrowForwardIosIcon
                                            sx={{ cursor: 'pointer', color: 'green' }}
                                            onClick={ () => props.handleRowLink(row.id) }
                                        />
                                    }
                                </Stack>
                            </StyledTableCell>
                        </StyledTableRow>
                        
                        return(
                            <>
                                {   filter !== '' 
                                    ? row.nome.toLowerCase().includes(filter.toLowerCase()) && el
                                    : el
                                }
                            </>
                        )
                    })}

                    {filter === '' && emptyRows > 0 && (
                        <TableRow style={{ height: 46.6 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    )}

                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={props.rowsPerPageOptions}
                            colSpan={3}
                            count={filter !== '' 
                                    ? rows.filter( (row) => row.nome.toLowerCase().includes(filter.toLowerCase()) ).length
                                    : rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}

function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <LastPageIcon />
        </IconButton>
      </Box>
    );
}