import * as React from 'react';
import axios from 'axios';

import { createTheme, styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Chart from "react-apexcharts";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import Thermometer from "../UI/Thermometer";
import SearchBar from '../UI/SearchBar';

import EditableSelect from '../UI/EditableSelect';

import { grey, blue, red } from '@mui/material/colors';

import { config } from '../config';
import { getRole, getClient } from '../helpers/login';

const viewType = {
    normale: 0,
    identificativo: 1,
    argomento: 2,
    aree: 3
}

const statoType = {
    nonConforme: 1,
    inGestione: 2,
    conforme: 3
}

const customTheme = createTheme({
    title: blue[800],
    background: grey[200],
    table: {
        head: grey[800],
        expiry: red[600]
    }
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: customTheme.table.head,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

function showSelectClient(userRole) {
    switch(userRole) {
        case -1: return true; break;
        case 1: return true; break;
        default: return false;
    }
}

export default function Dashboard(props) {

    const [viewImportance, setViewImportance] = React.useState(viewType.identificativo)
    const [viewImportancePie, setViewImportancePie] = React.useState(viewType.identificativo)
    const [viewCritical, setViewCritical] = React.useState(viewType.identificativo)
    const [viewCriticalPie, setViewCriticalPie] = React.useState(viewType.identificativo)
    const [viewCompliance, setViewCompliance] = React.useState(viewType.identificativo)

    const [clients, setClients] = React.useState([])
    const [userClient, setUserClient] = React.useState(getClient())

    const [openText, setOpenText] = React.useState(false);
    const [modalText, setModalText] = React.useState("");

    const handleOpenText = () => {
        setOpenText(true);
    };

    const handleCloseText = () => {
        setOpenText(false);
    };

    function getClients() {
        axios.post(`${config.API_USERS}/clients/getClients.php`)
            .then((response) => {
                console.log (response.data)
                setClients( response.data );
                setUserClient( response.data.length > 0 ? response.data[0].id : 0)
            });
    }
    React.useEffect(() => {
       if(showSelectClient(getRole())) getClients()
    }, [])

    return(
        <>
            <Grid container spacing={2} sx={{ border: 0}}>
                { showSelectClient(getRole()) &&
                <Grid item lg={12} md={12} sm={12} justify="center" align="center" scroll="auto">
                    {/*<FormControl 
                        sx={{
                            ml: 2,
                            mb: 2,
                            mt: 2
                        }}
                    >
                        <FormLabel id="demo-row-radio-buttons-group-label">Clienti</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={userClient}
                            onChange={ (e) => setUserClient( parseInt(e.target.value) ) }
                        >
                        {clients.map((client) => (
                            <FormControlLabel value={client.id} control={<Radio />} label={client.nome} />
                        ))}
                        </RadioGroup>
                    </FormControl>*/}
                    <EditableSelect
                        style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem'}}
                        initialValue={userClient}
                        onBlur={ value => setUserClient( parseInt(value) ) }
                        options={clients}
                        editable={true}
                    />
                </Grid>
                }
                <Grid item md={2} sm={12} justify="center">
                    <Paper elevation={5} align='center' sx={{ paddingTop: 2 }}>
                        <Typography
                            variant="h4"
                            sx={{
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'cursive',
                                fontWeight: 700,
                                color: customTheme.title,
                                textDecoration: 'none',
                                justifyContent: "center"
                            }}
                        >
                            Stato di salute
                        </Typography>
                        <Paper elevation={0}>
                            <CustomThermometer 
                                userClient={userClient}
                            />
                        </Paper>
                    </Paper>
                </Grid>

                <Grid item container spacing={2} lg={10} md={10} sm={12}>

                    <Grid item md={12} sm={12} justify="center">
                        <Paper elevation={3} align='center' sx={{ paddingTop: 2, backgroundColor: customTheme.background }}>
                            <Typography
                                variant="h4"
                                
                                sx={{
                                    ml: 2,
                                    mb: 3,
                                    display: { xs: 'none', md: 'flex' },
                                    fontFamily: 'cursive',
                                    fontWeight: 700,
                                    color: customTheme.title,
                                    textDecoration: 'none',
                                    justifyContent: "center"
                                }}
                            >
                                Adempimenti in scadenza
                            </Typography>
                            <Paper elevation={0} sx={{ overflowX: 'scroll', overflowY: 'hidden' }}>
                                <NearlyTable
                                    perPage={10}
                                    rowsPerPageOptions={[10, 20, 30, { label: 'All', value: -1 }]}

                                    handleOpenText={handleOpenText}
                                    handleCloseText={handleCloseText}
                                    setModalText={setModalText}

                                    userClient={userClient}
                                />
                            </Paper>
                        </Paper>
                    </Grid>

                    <Grid item md={6} sm={12} justify="center">
                        <Paper elevation={3} align='center' sx={{ paddingTop: 2, backgroundColor: customTheme.background }}>
                            <Tooltip title="Indice analitico dato dal prodotto tra rischio sanzionatorio e impatto" arrow>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        display: { xs: 'none', md: 'flex' },
                                        fontFamily: 'cursive',
                                        fontWeight: 700,
                                        color: customTheme.title,
                                        textDecoration: 'none',
                                        justifyContent: "center"
                                    }}
                                >
                                    Importanza
                                </Typography>
                            </Tooltip>
                            <Paper elevation={0} sx={{ overflowX: 'scroll', overflowY: 'hidden' }}>
                                <ChartImportanza
                                    view={viewImportance}
                                    userClient={userClient}
                                />
                            </Paper>
                            <FormControl 
                                sx={{
                                    ml: 2,
                                    mb: 2,
                                    mt: 2
                                }}
                            >
                                <FormLabel id="demo-row-radio-buttons-group-label">Vista</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={viewImportance}
                                    onChange={ (e) => setViewImportance( parseInt(e.target.value) ) }
                                >
                                    {/*<FormControlLabel value={viewType.normale} control={<Radio />} label="Normale" /> */}
                                    <FormControlLabel value={viewType.identificativo} control={<Radio />} label="Identificativo" />
                                    <FormControlLabel value={viewType.argomento} control={<Radio />} label="Argomento" />
                                    <FormControlLabel value={viewType.aree} control={<Radio />} label="Aree" />
                                </RadioGroup>
                            </FormControl>
                        </Paper>

                        <Paper elevation={3} align='center' sx={{ paddingTop: 2, mt: 3, backgroundColor: customTheme.background }}>
                            <Tooltip title="Incidenza di importanza e criticità in percentuale sulle aree di azione" arrow>
                                <Typography
                                    variant="h4"
                                    
                                    sx={{
                                        ml: 2,
                                        display: { xs: 'none', md: 'flex' },
                                        fontFamily: 'cursive',
                                        fontWeight: 700,
                                        color: customTheme.title,
                                        textDecoration: 'none',
                                        justifyContent: "center"
                                    }}
                                >
                                    Aree di interesse
                                </Typography>
                            </Tooltip>
                            <Paper elevation={0} sx={{ overflowX: 'scroll', overflowY: 'hidden' }}>
                                <ChartImportanzaPie
                                    view={viewImportancePie}
                                    userClient={userClient}
                                />
                            </Paper>
                            <FormControl 
                                sx={{
                                    ml: 2,
                                    mb: 2,
                                    mt: 2
                                }}
                            >
                                <FormLabel id="demo-row-radio-buttons-group-label">Vista</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={viewImportancePie}
                                    onChange={ (e) => setViewImportancePie( parseInt(e.target.value) ) }
                                >
                                    {/*<FormControlLabel value={viewType.normale} control={<Radio />} label="Normale" /> */}
                                    <FormControlLabel value={viewType.identificativo} control={<Radio />} label="Identificativo" />
                                    <FormControlLabel value={viewType.argomento} control={<Radio />} label="Argomento" />
                                    <FormControlLabel value={viewType.aree} control={<Radio />} label="Aree" />
                                </RadioGroup>
                            </FormControl>
                        </Paper>
                    </Grid>

                    <Grid item md={6} sm={12} justify="center">
                        <Paper elevation={3} align='center' sx={{ paddingTop: 2, backgroundColor: customTheme.background }}>
                            <Tooltip title="Indice analitico dato dal prodotto tra Importanza e grado di raggiungimento della conformità" arrow>
                                <Typography
                                    variant="h4"
                                    
                                    sx={{
                                        ml: 2,
                                        display: { xs: 'none', md: 'flex' },
                                        fontFamily: 'cursive',
                                        fontWeight: 700,
                                        color: customTheme.title,
                                        textDecoration: 'none',
                                        justifyContent: "center"
                                    }}
                                >
                                    Criticità
                                </Typography>
                            </Tooltip>
                            <Paper elevation={0} sx={{ overflowX: 'scroll', overflowY: 'hidden' }}>
                                <ChartCritical
                                    view={viewCritical}
                                    userClient={userClient}
                                />
                            </Paper>
                            <FormControl 
                                sx={{
                                    ml: 2,
                                    mb: 2,
                                    mt: 2
                                }}
                            >
                                <FormLabel id="demo-row-radio-buttons-group-label">Vista</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={viewCritical}
                                    onChange={ (e) => setViewCritical( parseInt(e.target.value) ) }
                                >
                                    {/*<FormControlLabel value={viewType.normale} control={<Radio />} label="Normale" /> */}
                                    <FormControlLabel value={viewType.identificativo} control={<Radio />} label="Identificativo" />
                                    <FormControlLabel value={viewType.argomento} control={<Radio />} label="Argomento" />
                                    <FormControlLabel value={viewType.aree} control={<Radio />} label="Aree" />
                                </RadioGroup>
                            </FormControl>
                        </Paper>

                        <Paper elevation={3} align='center' sx={{ paddingTop: 2, mt: 3, backgroundColor: customTheme.background }}>
                            <Tooltip title="Incidenza di importanza e criticità in percentuale sulle aree di azione" arrow>
                                <Typography
                                    variant="h4"
                                    
                                    sx={{
                                        ml: 2,
                                        display: { xs: 'none', md: 'flex' },
                                        fontFamily: 'cursive',
                                        fontWeight: 700,
                                        color: customTheme.title,
                                        textDecoration: 'none',
                                        justifyContent: "center"
                                    }}
                                >
                                    Aree di interesse
                                </Typography>
                            </Tooltip>
                            <Paper elevation={0} sx={{ overflowX: 'scroll', overflowY: 'hidden' }}>
                                <ChartCriticalPie
                                    view={viewCriticalPie}
                                    userClient={userClient}
                                />
                            </Paper>
                            <FormControl 
                                sx={{
                                    ml: 2,
                                    mb: 2,
                                    mt: 2
                                }}
                            >
                                <FormLabel id="demo-row-radio-buttons-group-label">Vista</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={viewCriticalPie}
                                    onChange={ (e) => setViewCriticalPie( parseInt(e.target.value) ) }
                                >
                                    {/*<FormControlLabel value={viewType.normale} control={<Radio />} label="Normale" /> */}
                                    <FormControlLabel value={viewType.identificativo} control={<Radio />} label="Identificativo" />
                                    <FormControlLabel value={viewType.argomento} control={<Radio />} label="Argomento" />
                                    <FormControlLabel value={viewType.aree} control={<Radio />} label="Aree" />
                                </RadioGroup>
                            </FormControl>
                        </Paper>
                    </Grid>

                    <Grid item md={12} sm={12} justify="center">
                        <Paper elevation={3} align='center' sx={{ paddingTop: 2, backgroundColor: customTheme.background }}>
                            <Tooltip title="Monitoraggio della gestione degli adempimenti" arrow>
                                <Typography
                                    variant="h4"
                                    
                                    sx={{
                                        ml: 2,
                                        display: { xs: 'none', md: 'flex' },
                                        fontFamily: 'cursive',
                                        fontWeight: 700,
                                        color: customTheme.title,
                                        textDecoration: 'none',
                                        justifyContent: "center"
                                    }}
                                >
                                    Stato di conformità
                                </Typography>
                            </Tooltip>
                            <Paper elevation={0} sx={{ overflowX: 'scroll', overflowY: 'hidden' }}>
                                <ChartCompliance
                                    view={viewCompliance}
                                    userClient={userClient}
                                />
                            </Paper>
                            <FormControl 
                                sx={{
                                    ml: 2,
                                    mb: 2,
                                    mt: 2
                                }}
                            >
                                <FormLabel id="demo-row-radio-buttons-group-label">Vista</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={viewCompliance}
                                    onChange={ (e) => setViewCompliance( parseInt(e.target.value) ) }
                                >
                                    {/*<FormControlLabel value={viewType.normale} control={<Radio />} label="Normale" /> */}
                                    <FormControlLabel value={viewType.identificativo} control={<Radio />} label="Identificativo" />
                                    <FormControlLabel value={viewType.argomento} control={<Radio />} label="Argomento" />
                                    <FormControlLabel value={viewType.aree} control={<Radio />} label="Aree" />
                                </RadioGroup>
                            </FormControl>
                        </Paper>
                    </Grid>

                </Grid>

            </Grid>

            <TextDialog 
                open={openText}
                handleClose={handleCloseText}

                text={modalText}
            />
        </>
    )
}

function CustomThermometer(props) {

    const { userClient } = props

    const topbarHeight = 68.5
    const footerHeight = 92

    const width = 40

    const basePadding = 1
    const baseHeight = width*2.5
    const baseShift = 40

    const height = window.innerHeight - (footerHeight + topbarHeight) - (basePadding*2 + baseHeight + baseShift*2)

    const [current, setCurrent] = React.useState(0)
    function getData() {
        fetch(`${config.API_DASHBOARD}/chartThermometer.php`, {
            method: 'POST',
            body: JSON.stringify({
              clientId: userClient,
            }),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Thermometer result:', data);

            let sum = 0
            data.forEach(el => sum += el.grado )
            setCurrent( data.length ? Math.round(sum / data.length) : 0 )
        })
    }
    React.useEffect(() => {
       getData()
    }, [userClient])

    return(
        <Thermometer
            min={0}
            max={100}
            width={width}
            height={height}
            backgroundColor={'lightGrey'}
            fillColor={`hsl(${current},100%,50%)`}
            current={current}
        />
    )
}

function ChartImportanza (props) {

    const { userClient } = props

    const [series, setSeries] = React.useState([])
    const [categories, setCategories] = React.useState([])

    const [view, setView] = React.useState(props.view)
    React.useEffect(() => {
        setView(props.view)
    }, [props.view])

    const [data, setData] = React.useState([])
    function getData() {
        fetch(`${config.API_DASHBOARD}/chartImportance.php`, {
            method: 'POST',
            body: JSON.stringify({
              clientId: userClient,
            }),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Post result:', data);
            setData(data)
        })
    }
    React.useEffect(() => {
        getData()
    }, [userClient])

    React.useEffect(() => {

        let newSeries = []
        let newCategories = []

        switch(parseInt(view)) {

            case viewType.normale:
            {
                let newSerieData = []
                data.forEach(a => {
                    newSerieData.push(a.rischio * a.valutazione)
                    newCategories.push(`${a.identificativo} - ${a.articolo} - ${a.comma}`)
                })
                newSeries.push({
                    name: 'Importanza',
                    data: newSerieData
                })
                break;
            }

            case viewType.identificativo:
            {
                let tmp = []
                data.forEach(a => {
                    if(tmp[a.identificativo] === undefined) tmp[a.identificativo] = 0
                    tmp[a.identificativo] += a.rischio * a.valutazione
                })

                let newSerieData = []
                for (const [identificativo, value] of Object.entries(tmp)) {
                    newSerieData.push(value)
                    newCategories.push(identificativo)
                }
                newSeries.push({
                    name: 'Importanze',
                    data: newSerieData
                })
                break;
            }

            case viewType.argomento:
            {
                let tmp = []
                data.forEach(a => {
                    if(tmp[a.argomento] === undefined) tmp[a.argomento] = 0
                    tmp[a.argomento] += a.rischio * a.valutazione
                })

                let newSerieData = []
                for (const [argomento, value] of Object.entries(tmp)) {
                    newSerieData.push(value)
                    newCategories.push(argomento)
                }
                newSeries.push({
                    name: 'Importanze',
                    data: newSerieData
                })
                break;
            }

            case viewType.aree:
            {
                let tmp = []
                data.forEach(a => {
                    if(tmp[a.aree] === undefined) tmp[a.aree] = 0
                    tmp[a.aree] += a.rischio * a.valutazione
                })

                let newSerieData = []
                for (const [argomento, value] of Object.entries(tmp)) {
                    newSerieData.push(value)
                    newCategories.push(argomento)
                }
                newSeries.push({
                    name: 'Importanze',
                    data: newSerieData
                })
                break;
            }

            default:
                console.log(`Vista non disponibile ${view}.`);

        }

        setSeries(newSeries)
        setCategories(newCategories)

    }, [data, view])

    const columnWidth = 75
    const dynamicWidth = series.length > 0 ? series[0].data.length * columnWidth : 0;
    const chartWidth = dynamicWidth < window.innerWidth/12*4 ? '100%' : dynamicWidth;

    const options = {
        chart: {
          id: "basic-bar",
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: categories
        }
    }

    return(
        <Chart
            options={options}
            series={series}
            type="bar"
            width={chartWidth}
            height="auto"
        />
    )
}

function ChartImportanzaPie (props) {

    const { userClient } = props

    const [series, setSeries] = React.useState([])
    const [labels, setLabels] = React.useState([])

    const [view, setView] = React.useState(props.view)
    React.useEffect(() => {
        setView(props.view)
    }, [props.view])

    const [data, setData] = React.useState([])
    function getData() {
        fetch(`${config.API_DASHBOARD}/chartImportance.php`, {
            method: 'POST',
            body: JSON.stringify({
              clientId: userClient,
            }),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Post result:', data);
            setData(data)
        })
    }
    React.useEffect(() => {
        getData()
    }, [userClient])

    React.useEffect(() => {

        let newSeries = []
        let newLabels = []

        switch(parseInt(view)) {

            case viewType.normale:
            {
                data.forEach(a => {
                    newSeries.push(a.rischio * a.valutazione)
                    newLabels.push(`${a.identificativo} - ${a.articolo} - ${a.comma}`)
                })
                break;
            }

            case viewType.identificativo:
            {
                let tmp = []
                data.forEach(a => {
                    if(tmp[a.identificativo] === undefined) tmp[a.identificativo] = 0
                    tmp[a.identificativo] += a.rischio * a.valutazione
                })

                for (const [identificativo, value] of Object.entries(tmp)) {
                    newSeries.push(value)
                    newLabels.push(identificativo)
                }
                break;
            }

            case viewType.argomento:
            {
                let tmp = []
                data.forEach(a => {
                    if(tmp[a.argomento] === undefined) tmp[a.argomento] = 0
                    tmp[a.argomento] += a.rischio * a.valutazione
                })

                for (const [identificativo, value] of Object.entries(tmp)) {
                    newSeries.push(value)
                    newLabels.push(identificativo)
                }
                break;
            }

            case viewType.aree:
            {
                let tmp = []
                data.forEach(a => {
                    if(tmp[a.aree] === undefined) tmp[a.aree] = 0
                    tmp[a.aree] += a.rischio * a.valutazione
                })

                for (const [identificativo, value] of Object.entries(tmp)) {
                    newSeries.push(value)
                    newLabels.push(identificativo)
                }
                break;
            }

            default:
                console.log(`Vista non disponibile ${view}.`);

        }

        console.log(newSeries, newLabels)

        setSeries(newSeries)
        setLabels(newLabels)

    }, [data, view])

    const options = {
        labels: labels,
        chart: {
          id: "basic-bar",
          toolbar: {
            show: false
          }
        },
        theme: {
            palette: 'palette2'
        },
        legend: {
            show: false,
        }
    }

    return(
        <Chart
            options={options}
            series={series}
            type="pie"
            height="auto"
        />
    )
}

function ChartCritical (props) {

    const { userClient } = props

    const [series, setSeries] = React.useState([])
    const [categories, setCategories] = React.useState([])

    const [view, setView] = React.useState(props.view)
    React.useEffect(() => {
        setView(props.view)
    }, [props.view])

    const [data, setData] = React.useState([])
    function getData() {
        fetch(`${config.API_DASHBOARD}/chartCritical.php`, {
            method: 'POST',
            body: JSON.stringify({
              clientId: userClient,
            }),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Post result:', data);
            setData(data)
        })
    }
    React.useEffect(() => {
        getData()
    }, [userClient])

    React.useEffect(() => {

        let newSeries = []
        let newCategories = []

        switch(parseInt(view)) {

            case viewType.normale:
            {
                let newSerieData = []
                data.forEach(a => {
                    newSerieData.push(Math.round(a.rischio * a.valutazione * a.grado / 100))
                    newCategories.push(`${a.identificativo} - ${a.articolo} - ${a.comma}`)
                })
                newSeries.push({
                    name: 'Importanza',
                    data: newSerieData
                })
                break;
            }

            case viewType.identificativo:
            {
                let tmp = []
                data.forEach(a => {
                    if(tmp[a.identificativo] === undefined) tmp[a.identificativo] = 0
                    tmp[a.identificativo] += Math.round(a.rischio * a.valutazione * a.grado / 100)
                })

                let newSerieData = []
                for (const [identificativo, value] of Object.entries(tmp)) {
                    newSerieData.push(value)
                    newCategories.push(identificativo)
                }
                newSeries.push({
                    name: 'Importanze',
                    data: newSerieData
                })
                break;
            }

            case viewType.argomento:
            {
                let tmp = []
                data.forEach(a => {
                    if(tmp[a.argomento] === undefined) tmp[a.argomento] = 0
                    tmp[a.argomento] += Math.round(a.rischio * a.valutazione * a.grado / 100)
                })

                let newSerieData = []
                for (const [argomento, value] of Object.entries(tmp)) {
                    newSerieData.push(value)
                    newCategories.push(argomento)
                }
                newSeries.push({
                    name: 'Importanze',
                    data: newSerieData
                })
                break;
            }

            case viewType.aree:
            {
                let tmp = []
                data.forEach(a => {
                    if(tmp[a.aree] === undefined) tmp[a.aree] = 0
                    tmp[a.aree] += Math.round(a.rischio * a.valutazione * a.grado / 100)
                })

                let newSerieData = []
                for (const [argomento, value] of Object.entries(tmp)) {
                    newSerieData.push(value)
                    newCategories.push(argomento)
                }
                newSeries.push({
                    name: 'Importanze',
                    data: newSerieData
                })
                break;
            }

            default:
                console.log(`Vista non disponibile ${view}.`);

        }

        setSeries(newSeries)
        setCategories(newCategories)

    }, [data, view])

    const columnWidth = 75
    const dynamicWidth = series.length > 0 ? series[0].data.length * columnWidth : 0;
    const chartWidth = dynamicWidth < window.innerWidth/12*4 ? '100%' : dynamicWidth;

    const options = {
        chart: {
          id: "basic-bar",
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: categories
        },
        colors: ['#00e396']
    }

    return(
        <Chart
            options={options}
            series={series}
            type="bar"
            width={chartWidth}
            height="auto"
        />
    )
}

function ChartCriticalPie (props) {

    const { userClient } = props

    const [series, setSeries] = React.useState([])
    const [labels, setLabels] = React.useState([])

    const [view, setView] = React.useState(props.view)
    React.useEffect(() => {
        setView(props.view)
    }, [props.view])

    const [data, setData] = React.useState([])
    function getData() {
        fetch(`${config.API_DASHBOARD}/chartCriticalPie.php`, {
            method: 'POST',
            body: JSON.stringify({
              clientId: userClient,
            }),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Post result:', data);
            setData(data)
        })
    }
    React.useEffect(() => {
        getData()
    }, [userClient])

    React.useEffect(() => {

        let newSeries = []
        let newLabels = []

        switch(parseInt(view)) {

            case viewType.normale:
            {
                data.forEach(a => {
                    newSeries.push(a.rischio * a.valutazione * a.grado)
                    newLabels.push(`${a.identificativo} - ${a.articolo} - ${a.comma}`)
                })
                break;
            }

            case viewType.identificativo:
            {
                let tmp = []
                data.forEach(a => {
                    if(tmp[a.identificativo] === undefined) tmp[a.identificativo] = 0
                    tmp[a.identificativo] += a.rischio * a.valutazione * a.grado
                })

                for (const [identificativo, value] of Object.entries(tmp)) {
                    newSeries.push(value)
                    newLabels.push(identificativo)
                }
                break;
            }

            case viewType.argomento:
            {
                let tmp = []
                data.forEach(a => {
                    if(tmp[a.argomento] === undefined) tmp[a.argomento] = 0
                    tmp[a.argomento] += a.rischio * a.valutazione * a.grado
                })

                for (const [identificativo, value] of Object.entries(tmp)) {
                    newSeries.push(value)
                    newLabels.push(identificativo)
                }
                break;
            }

            case viewType.aree:
            {
                let tmp = []
                data.forEach(a => {
                    if(tmp[a.aree] === undefined) tmp[a.aree] = 0
                    tmp[a.aree] += a.rischio * a.valutazione * a.grado
                })

                for (const [identificativo, value] of Object.entries(tmp)) {
                    newSeries.push(value)
                    newLabels.push(identificativo)
                }
                break;
            }

            default:
                console.log(`Vista non disponibile ${view}.`);

        }

        console.log(newSeries, newLabels)

        setSeries(newSeries)
        setLabels(newLabels)

    }, [data, view])

    const options = {
        labels: labels,
        chart: {
          id: "basic-bar",
          toolbar: {
            show: false
          }
        },
        legend: {
            show: false,
        }
    }

    return(
        <Chart
            options={options}
            series={series}
            type="pie"
            height="auto"
        />
    )
}

function ChartCompliance (props) {

    const { userClient } = props

    const [series, setSeries] = React.useState([])
    const [categories, setCategories] = React.useState([])

    const [view, setView] = React.useState(props.view)
    React.useEffect(() => {
        setView(props.view)
    }, [props.view])

    const [data, setData] = React.useState([])
    function getData() {
        fetch(`${config.API_DASHBOARD}/chartCompliance.php`, {
            method: 'POST',
            body: JSON.stringify({
              clientId: userClient,
            }),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Post result:', data);
            setData(data)
        })
    }
    React.useEffect(() => {
        getData()
    }, [userClient])

    React.useEffect(() => {

        let newSeries = []
        let newCategories = []

        switch(parseInt(view)) {

            case viewType.normale:
            {
                let newSerieData = { nonConforme: [], inGestione: [], conforme: [] }
                data.forEach(a => {
                    
                    switch(a.stato) {
                        case statoType.nonConforme:
                        {
                            newSerieData.nonConforme.push(1)
                            newSerieData.inGestione.push(0)
                            newSerieData.conforme.push(0)
                            break;
                        }
                        case statoType.inGestione:
                        {
                            newSerieData.nonConforme.push(0)
                            newSerieData.inGestione.push(1)
                            newSerieData.conforme.push(0)
                            break;
                        }
                        case statoType.conforme:
                        {
                            newSerieData.nonConforme.push(0)
                            newSerieData.inGestione.push(0)
                            newSerieData.conforme.push(1)
                            break;
                        }
                    }
                    newCategories.push(`${a.identificativo} - ${a.articolo} - ${a.comma}`)
                })
                newSeries.push({
                    name: 'Non conforme',
                    data: newSerieData.nonConforme
                })
                newSeries.push({
                    name: 'In gestione',
                    data: newSerieData.inGestione
                })
                newSeries.push({
                    name: 'Conforme',
                    data: newSerieData.conforme
                })
                break;
            }

            case viewType.identificativo:
            {
                let tmp = []
                data.forEach(a => {
                    if(tmp[a.identificativo] === undefined) tmp[a.identificativo] = { nonConforme: 0, inGestione: 0, conforme: 0 }
                    switch(a.stato) {
                        case statoType.nonConforme:
                        {
                            tmp[a.identificativo].nonConforme++
                            break;
                        }
                        case statoType.inGestione:
                        {
                            tmp[a.identificativo].inGestione++
                            break;
                        }
                        case statoType.conforme:
                        {
                            tmp[a.identificativo].conforme++
                            break;
                        }
                    }
                    
                })

                let newSerieData = { nonConforme: [], inGestione: [], conforme: [] }
                for (const [identificativo, value] of Object.entries(tmp)) {
                    newSerieData.nonConforme.push(value.nonConforme)
                    newSerieData.inGestione.push(value.inGestione)
                    newSerieData.conforme.push(value.conforme)
                    newCategories.push(identificativo)
                }
                newSeries.push({
                    name: 'Non conforme',
                    data: newSerieData.nonConforme
                })
                newSeries.push({
                    name: 'In gestione',
                    data: newSerieData.inGestione
                })
                newSeries.push({
                    name: 'Conforme',
                    data: newSerieData.conforme
                })
                break;
            }

            case viewType.argomento:
            {
                let tmp = []
                data.forEach(a => {
                    if(tmp[a.argomento] === undefined) tmp[a.argomento] = { nonConforme: 0, inGestione: 0, conforme: 0 }
                    switch(a.stato) {
                        case statoType.nonConforme:
                        {
                            tmp[a.argomento].nonConforme++
                            break;
                        }
                        case statoType.inGestione:
                        {
                            tmp[a.argomento].inGestione++
                            break;
                        }
                        case statoType.conforme:
                        {
                            tmp[a.argomento].conforme++
                            break;
                        }
                    }
                    
                })

                let newSerieData = { nonConforme: [], inGestione: [], conforme: [] }
                for (const [argomento, value] of Object.entries(tmp)) {
                    newSerieData.nonConforme.push(value.nonConforme)
                    newSerieData.inGestione.push(value.inGestione)
                    newSerieData.conforme.push(value.conforme)
                    newCategories.push(argomento)
                }
                newSeries.push({
                    name: 'Non conforme',
                    data: newSerieData.nonConforme
                })
                newSeries.push({
                    name: 'In gestione',
                    data: newSerieData.inGestione
                })
                newSeries.push({
                    name: 'Conforme',
                    data: newSerieData.conforme
                })
                break;
            }

            case viewType.aree:
            {
                let tmp = []
                data.forEach(a => {
                    if(tmp[a.aree] === undefined) tmp[a.aree] = { nonConforme: 0, inGestione: 0, conforme: 0 }
                    switch(a.stato) {
                        case statoType.nonConforme:
                        {
                            tmp[a.aree].nonConforme++
                            break;
                        }
                        case statoType.inGestione:
                        {
                            tmp[a.aree].inGestione++
                            break;
                        }
                        case statoType.conforme:
                        {
                            tmp[a.aree].conforme++
                            break;
                        }
                    }
                    
                })

                let newSerieData = { nonConforme: [], inGestione: [], conforme: [] }
                for (const [aree, value] of Object.entries(tmp)) {
                    newSerieData.nonConforme.push(value.nonConforme)
                    newSerieData.inGestione.push(value.inGestione)
                    newSerieData.conforme.push(value.conforme)
                    newCategories.push(aree)
                }
                newSeries.push({
                    name: 'Non conforme',
                    data: newSerieData.nonConforme
                })
                newSeries.push({
                    name: 'In gestione',
                    data: newSerieData.inGestione
                })
                newSeries.push({
                    name: 'Conforme',
                    data: newSerieData.conforme
                })
                break;
            }

            default:
                console.log(`Vista non disponibile ${view}.`);

        }

        setSeries(newSeries)
        setCategories(newCategories)

    }, [data, view])

    const columnWidth = 75
    const dynamicWidth = series.length > 0 ? series[0].data.length * columnWidth : 0;
    const chartWidth = dynamicWidth < window.innerWidth/12*4 ? '100%' : dynamicWidth;

    const options = {
        chart: {
          id: "basic-bar",
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: categories
        },
        colors: ['#ab0000', '#c5c500', '#008000']        
    }

    return(
        <Chart
            options={options}
            series={series}
            type="bar"
            width={chartWidth}
            height="auto"
        />
    )
}

function NearlyTable(props) {

    const { userClient } = props

    const filterColumns = [ 'identificativo', 'titolo', 'capo', 'articolo', 'comma', 'responsabile' ]

    const [rows, setRows] = React.useState([])

    function getAssessments() {
        fetch(`${config.API_DASHBOARD}/nearlyTable.php`, {
          method: 'POST',
          body: JSON.stringify({
              clientId: userClient,
          }),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Post result:', data);
            setRows( data )
        })
    }
    
      React.useEffect(() => {
        getAssessments()
      }, [userClient])

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.perPage !== undefined ? props.perPage : props.rowsPerPageOptions[0]);

    const [filter, setFilter] = React.useState('')

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    let nFilteredRow = 0
    return (
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell align='center'>Identificativo</StyledTableCell>
                        <StyledTableCell align='center'>Titolo</StyledTableCell>
                        <StyledTableCell align='center'>Capo</StyledTableCell>
                        <StyledTableCell align='center'>Articolo</StyledTableCell>
                        <StyledTableCell align='center'>Comma</StyledTableCell>
                        <StyledTableCell align='center'>Responsabile</StyledTableCell>
                        <StyledTableCell align='right'>Scadenza</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell align="center" colSpan={7} sx={{ padding: '0!important' }}>
                            <Paper elevation={2}>
                                <SearchBar
                                    value={filter}
                                    onChange={(newValue) => setFilter(newValue) }
                                    onCancelSearch={() => setFilter('') }
                                />
                            </Paper>
                        </TableCell>
                    </TableRow>
                    {(
                        rowsPerPage > 0
                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : rows
                        ).map((row) => {
                            
                            let el =
                            <StyledTableRow key={row.id} sx={{ backgroundColor: parseInt(row.days) <= 0 ? customTheme.table.expiry : 'white'}}>
                                <StyledTableCell 
                                    align="center"
                                    sx={{ fontSize: '20px', fontWeight: 'bolder', fontStyle: 'italic' }}    
                                ><b><em>{row.identificativo}</em></b></StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    sx={{ cursor: 'pointer'}}
                                    onClick={() => {
                                        props.setModalText(row.testo_titolo)
                                        props.handleOpenText()
                                    }}
                                >{row.titolo}</StyledTableCell>
                                <StyledTableCell 
                                    align="center" 
                                    sx={{ cursor: 'pointer'}}
                                    onClick={() => {
                                        props.setModalText(row.testo_capo)
                                        props.handleOpenText()
                                    }}
                                >{row.capo}</StyledTableCell>
                                <StyledTableCell 
                                    align="center" 
                                    sx={{ cursor: 'pointer'}}
                                    onClick={() => {
                                        props.setModalText(row.testo_articolo)
                                        props.handleOpenText()
                                    }}
                                >{row.articolo}</StyledTableCell>
                                <StyledTableCell 
                                    align="center" 
                                    sx={{ cursor: 'pointer'}}
                                    onClick={() => {
                                        props.setModalText(row.testo_comma)
                                        props.handleOpenText()
                                    }}
                                >{row.comma}</StyledTableCell>
                                <StyledTableCell align="center">{row.responsabile === null ? '-' : row.responsabile}</StyledTableCell>
                                <StyledTableCell align="right">{formatDateLabel(row.scadenza)}</StyledTableCell>
                            </StyledTableRow>

                            let f = false
                            filterColumns.forEach(c => {
                                if( typeof row[c] === 'string' && row[c].toLowerCase().includes(filter.toLowerCase()) ) f = true
                            })

                            if(f) nFilteredRow++

                            return(
                                <>
                                    {   filter !== '' 
                                        ? f && el
                                        : el
                                    }
                                </>
                            )
                        })
                    }

                    {filter === '' && emptyRows > 0 && (
                        <TableRow style={{ height: 46.6 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    )}

                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={props.rowsPerPageOptions}
                            colSpan={6}
                            count={filter !== '' 
                                    ?   nFilteredRow
                                    :   rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );

    function formatDateLabel(date) {
        let dParts = date.split('-')
        return dParts[2] + '/' + dParts[1] + '/' + dParts[0]
    }
}

function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <LastPageIcon />
        </IconButton>
      </Box>
    );
}

function TextDialog(props) {

    return (
      <div>
        <Dialog
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {props.text}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    );
  }