import * as React from 'react';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import EditableLabel from '../UI/EditableLabel';
import SearchBar from '../UI/SearchBar'

import { getRole } from '../helpers/login';

function isAbleToDeleteRow(userRole) {
    switch(userRole) {
      case -1: return true
      case 1: return false
      case 2: return false
      case 4: return false
      default: return false
    }
}

let userRole = null;

export default function PaginatedTable(props) {

    userRole = getRole();

    function createBlankRow() {
        return {
            id: 0,
            nome: ''
        }
    }

    const [rows, setRows] = React.useState(props.rows)
    const [isAddingRow, setIsAddingRow] = React.useState(false)

    React.useEffect(() => {
        setRows(props.rows)
    }, [props.rows])

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.perPage !== undefined ? props.perPage : props.rowsPerPageOptions[0]);

    const [filter, setFilter] = React.useState('')

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
    }));
      
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
    }));

    const handleCreateNewRow = () => {
        let newRows = [...rows]
        newRows.push(createBlankRow())
        setRows(newRows)
        setIsAddingRow(true)
        let nPages = rows.length%rowsPerPage === 0 ? ( rows.length / rowsPerPage ) + 1 : Math.ceil(rows.length / rowsPerPage)
        setPage(nPages-1)
    }
    
    const handleDiscardNewRow = () => {
        let newRows = [...rows]
        newRows.pop()
        setRows(newRows)
        setIsAddingRow(false)
        setPage(0)
    }

    const handleSaveChangesNewRows = (data) => {
        if(data !== '') {
            console.log('Adding', data)
            let newRows = [...rows]
            for(let i=0; i<newRows.length; i++) { if( newRows[i].id === 0 ) { newRows[i]['nome'] = data; }}
            console.log(newRows)
            setRows(newRows)
            setIsAddingRow(false)

            fetch(props.addEndpoint, {
                method: 'POST',
                body: JSON.stringify({
                    value: data
                }),
            })
            .then(response => response.json())
            .then(data => {
                console.log('Post result:', data);
                if ( !data ) alert('Errore durante la modifica. Aggiornare la pagina e riprovare')
                else props.reloadRows()
            })

        }
    }

    const handleSaveChanges = (id, data) => {
        console.log(`Saving changes (${id}):`, data)
        let newRows = [...rows]
        for(let i=0; i<newRows.length; i++) { if( newRows[i].id === id ) newRows[i]['nome'] = data}
        console.log(newRows)
        setRows(newRows)

        fetch(props.modifyEndpoint, {
            method: 'POST',
            body: JSON.stringify({
                id: id,
                value: data
            }),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Post result:', data);
            if ( !data ) alert('Errore durante la modifica. Aggiornare la pagina e riprovare')
        })

        console.log('Row saved')
    }

    const handleDeleteRow = (id) => {
        console.log('Deleting row', id)
  
        fetch(props.deleteEndpoint, {
          method: 'POST',
          body: JSON.stringify({
            id: id
          }),
        })
        .then(response => response.json())
        .then(data => {
          console.log('Post result:', data);
          if ( !data ) alert('Errore durante la modifica. Aggiornare la pagina e riprovare')
          else props.reloadRows()
        })
    }
      
    return (
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell align='center' justify="center">
                            {
                                isAddingRow === false
                                ?
                                <AddCircleOutlineIcon
                                    sx={{ cursor: 'pointer', float: 'left' }}
                                    onClick={handleCreateNewRow}
                                />
                                :
                                <RemoveCircleOutlineIcon
                                    sx={{ cursor: 'pointer', float: 'left' }}
                                    onClick={handleDiscardNewRow}
                                />
                            }
                            {
                                props.title
                            }
                            {
                                isAddingRow === false
                                ?
                                <AddCircleOutlineIcon
                                    sx={{ cursor: 'pointer', float: 'right' }}
                                    onClick={handleCreateNewRow}
                                />
                                :
                                <RemoveCircleOutlineIcon
                                    sx={{ cursor: 'pointer', float: 'right' }}
                                    onClick={handleDiscardNewRow}
                                />
                            }
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    <SearchBar
                        value={filter}
                        onChange={(newValue) => setFilter(newValue) }
                        onCancelSearch={() => setFilter('') }
                    />

                    {(rowsPerPage > 0
                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : rows
                    ).map((row) => {

                        const el = 
                        <StyledTableRow key={row.id}>
                            <StyledTableCell sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <EditableLabel
                                    style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem'}}
                                    initialValue={row.nome}
                                    onBlur={value => { 
                                        if(isAddingRow === false || row.id !== 0) { handleSaveChanges(row.id, value) }
                                        else { handleSaveChangesNewRows(value) }  
                                    }}
                                    editable={props.editable}
                                />
                                <Stack sx={{ marginLeft: 'auto' }} direction="row" spacing={6}>
                                    { row.id !== 0 && isAbleToDeleteRow(userRole) &&
                                        <DeleteIcon
                                            sx={{ cursor: 'pointer', color: 'red' }}
                                            onClick={ () => handleDeleteRow(row.id) }
                                        />
                                    }
                                    {   props.handleRowLink !== undefined &&
                                        <ArrowForwardIosIcon
                                            sx={{ cursor: 'pointer', color: 'green' }}
                                            onClick={ () => props.handleRowLink(row.id) }
                                        />
                                    }
                                </Stack>
                            </StyledTableCell>
                        </StyledTableRow>
                        
                        return(
                            <>
                                {   filter !== '' 
                                    ? row.nome.toLowerCase().includes(filter.toLowerCase()) && el
                                    : el
                                }
                            </>
                        )
                    })}

                    {filter === '' && emptyRows > 0 && (
                        <TableRow style={{ height: 46.6 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    )}

                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={props.rowsPerPageOptions}
                            colSpan={3}
                            count={filter !== '' 
                                    ? rows.filter( (row) => row.nome.toLowerCase().includes(filter.toLowerCase()) ).length
                                    : rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}

function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <LastPageIcon />
        </IconButton>
      </Box>
    );
}