import * as React from 'react';
import axios from "axios";

import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import AddLinkIcon from '@mui/icons-material/AddLink';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import SaveIcon from '@mui/icons-material/Save';

import EditableLabel from '../UI/EditableLabel';
import EditableSelect from '../UI/EditableSelect';
import EditableDate from '../UI/EditableDate';

// import SearchBar from '../UI/SearchBar';

import { styled } from '@mui/material/styles';
import { blueGrey, blue, grey } from '@mui/material/colors';

import { config } from '../config';
import { getRole, getClient } from '../helpers/login';

const sectionViewTypes = {
  law: 0,
  title: 1,
  head: 2,
  article: 3,
  paragraph: 4,
  attached: 5,
  sentence: 6,
}

// Indica la profondità per variare le sezioni
const depthLevels = {
  law: 0,
  title: 1,
  head: 2,
  article: 3,
  paragraph: 4,
  attached: 5,
  sentence: 6,
}

function createBlankRow(userRole, userClient) {
  return {
    id: 0,
    identificativo: '', 
    titolo: '',
    ambito: 0, 
    tipologia: 0, 
    entrata: '0000-00-00',
    documento: null,
    link: null,
    cliente: userRole !== -1 && userRole !== 1 ? userClient : 0,
    titoli: [],
    capi: [],
    articoli: [],
    commi: [],
  }
}

function createBlankTitle() {
  return {
    id: 0,
    nome: '',
    descrizione: '',
    capi: [],
    articoli: [],
    commi: [],
  }
}

function createBlankHead() {
  return {
    id: 0,
    nome: '',
    descrizione: '',
    articoli: [],
    commi: [],
  }
}

function createBlankArticle() {
  return {
    id: 0,
    nome: '',
    descrizione: '',
    commi: [],
  }
}

function createBlankParagraph() {
  return {
    id: 0,
    nome: '',
    descrizione: '',
    assessment: 1,
  }
}

function isAbleToManageRow(userRole, lawClient = 1) {
  switch(userRole) {
    case -1: return true
    case 1: return true
    case 2: return lawClient !== 0 ? true : false
    case 3: return lawClient !== 0 ? true : false
    default: return false
  }
}

function showApplicability(role) {
  switch(role) {
    case -1: return false
    case 1: return false
    case 2: return false
    case 3: return false
    case 4: return false
    case 5: return false
    default: return true
  }
}

function showAssessment(role) {
  switch(role) {
    case -1: return false
    case 1: return false
    default: return true
  }
}

function showClient(role) {
  switch(role) {
    case 4: return false
    case 5: return false
    default: return true
  }
}

const borderColor = 'white'
const borderWidth = '2px'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: blueGrey[300],
    color: theme.palette.common.white,
    borderRight: `${borderWidth} solid ${borderColor}`,
    fontSize: 17,
    fontWeight: 'bolder'
  },
  /*[`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderRight: `${borderWidth} solid ${borderColor}`
  },*/
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    borderBottom: 0,
  },
}));

let userRole = null
let userClient = null

export default function LegalRepositoryTable(props) {

  const filterColumns = [ 'identificativo', 'titolo', 'ambito', 'tipologia' ]
  const [filter, setFilter] = React.useState('')

  const [section, setSection] = React.useState(sectionViewTypes.title)
  const [depth, setDepth] = React.useState(depthLevels.title)

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(0);
  const [deleteEndpoint, setDeleteEndpoint] = React.useState("");

  userRole = getRole()
  userClient = getClient()

  React.useEffect(() => {
    if(deleteId !== 0 && deleteEndpoint !== '') {
      console.log(deleteId, deleteEndpoint)
      setOpenDeleteDialog(true);
    }
  }, [deleteId, deleteEndpoint])

  const handleCloseDeleteDialog = () => {
    setDeleteId(0)
    setDeleteEndpoint("")
    setOpenDeleteDialog(false);
  }

  const handleDeleteRow = () => {
    if( !isAbleToManageRow(userRole) ) return
    fetch(deleteEndpoint, {
      method: 'POST',
      body: JSON.stringify({
        id: deleteId
      }),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Post result:', data);
      if ( !data ) alert('Errore durante la modifica. Aggiornare la pagina e riprovare')
      else {
        handleCloseDeleteDialog()
        getLaws()
      }
    })
  }

  React.useEffect(() => {
    props.setLegalId(null)
  }, [])

  const [rows, setRows] = React.useState([])
  const [isAddingRow, setIsAddingRow] = React.useState(false)

  function getLaws() {
    fetch(`${config.API_REPOSITORY}/laws/getLaws.php`, {
      method: 'POST',
      body: JSON.stringify({
        role: userRole,
        client: userClient
      }),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Loading laws', data)
      setRows(data);
      setSection(sectionViewTypes.title)
      setDepth(depthLevels.title)
      if ( !data ) alert('Errore durante il caricamento delle leggi. Aggiornare la pagina e riprovare')
    })
  }

  React.useEffect(() => {
    getLaws()
  }, [])

  const [scopes, setScopes] = React.useState([])
  const [acts, setActs] = React.useState([])
  const [clients, setClients] = React.useState([])

  function getScopes() {
      axios.post(`${config.API_CHOICES}/scopes/getScopes.php`)
          .then((response) => {
              console.log (response.data)
              setScopes( response.data );
          });
  }

  function getActs() {
      axios.post(`${config.API_CHOICES}/acts/getActs.php`)
          .then((response) => {
              console.log (response.data)
              setActs( response.data );
          });
  }

  function getClients() {
    axios.post(`${config.API_USERS}/clients/getClients.php`)
        .then((response) => {
            console.log (response.data)
            response.data.push({
              id: 0,
              nome: 'Globale'
            })
            setClients( response.data );
        });
  }

  React.useEffect(() => {
      getScopes()
      getActs()
      getClients()
  }, [])

  const handleCreateNewRow = () => {
    if( !isAbleToManageRow(userRole) ) return
    let newRows = [...rows]
    newRows.push(createBlankRow(userRole, userClient))
    setRows(newRows)
    setIsAddingRow(true)
  }

  const handleDiscardNewRow = () => {
    if( !isAbleToManageRow(userRole) ) return
    let newRows = [...rows]
    newRows.discardNewRow()
    setRows(newRows)
    setIsAddingRow(false)
  }

  const handleSaveChangesNewRows = (data, column) => {
    if( !isAbleToManageRow(userRole) ) return
    let newRows = [...rows]
    newRows.find((row) => { return row.id === 0 })[column] = data
    setRows(newRows)
    console.log('New row changed')
  }

  const handleNextSection = () => {
    setSection(section + 1)
  }

  const handlePreviousSection = () => {
    setSection(section - 1)
  }

  return (
      <>
        <Paper 
          elevation={2} 
          style={{marginBottom: '4px'}}
        >
            <Input
              onChange={(e) => setFilter(e.target.value)}
              style={{width: '100%', padding: '7px'}}
              placeholder='Cerca...'
            />
        </Paper>
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                      <StyledTableCell
                        //sx={{ display: 'flex', alignItems: 'center' }}
                        align="center"
                      >
                        {
                          isAddingRow === false
                          ?
                          <IconButton
                            sx={{ color: 'white' }}
                            disabled={!isAbleToManageRow(userRole)}
                          >
                            <AddCircleOutlineIcon
                              onClick={handleCreateNewRow}
                            />
                          </IconButton>
                          :
                          <IconButton
                            sx={{ color: 'white' }}
                            disabled={!isAbleToManageRow(userRole)}
                          >
                            <RemoveCircleOutlineIcon
                              onClick={handleDiscardNewRow}
                            />
                          </IconButton>
                        }
                      </StyledTableCell>
                      <StyledTableCell align="center">N. identificativo</StyledTableCell>
                      <StyledTableCell align="center">Titolo</StyledTableCell>
                      <StyledTableCell align="center">Ambito</StyledTableCell>
                      <StyledTableCell align="center">Tipologia Atto</StyledTableCell>
                      <StyledTableCell align="center">Entrata in vigore</StyledTableCell>
                      <StyledTableCell align="center">Documento ufficiale</StyledTableCell>
                      { showClient(userRole) && <StyledTableCell align="center">Cliente/azienda</StyledTableCell> }
                      {/* showApplicability(userRole) && <StyledTableCell align="center">Applicabilità</StyledTableCell>*/ }
                      <StyledTableCell align="center">Applicabilità</StyledTableCell>
                      <StyledTableCell align="center">Gestione</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => {
                            
                            let el =
                            <Row
                              key={`Law-${row.id}`} 
                              row={row}
                              filter={filter}
                              isAddingRow={isAddingRow}

                              scopes={scopes}
                              acts={acts}
                              clients={clients}
                              
                              section={section}
                              depth={depth}

                              lawClient={row.cliente}

                              setLegalId={props.setLegalId}
                              setClientId={props.setClientId}
                              setIsAddingRow={setIsAddingRow}
                              changeNewRow={handleSaveChangesNewRows}

                              handleNextSection={handleNextSection}
                              handlePreviousSection={handlePreviousSection}
                              setDepth={setDepth}
                              setSection={setSection}
                              reloadRows={getLaws}

                              setDeleteId={setDeleteId}
                              setDeleteEndpoint={setDeleteEndpoint}
                            />

                            if(row.titoli === undefined || row.articoli === undefined || row.capi === undefined || row.commi === undefined) return el

                            let f = false
                            filterColumns.forEach(c => {
                                if( typeof row[c] === 'string' && String(row[c]).toLowerCase().includes(filter.toLowerCase()) ) {
                                  f = true
                                } else if( typeof row[c] === 'number' ) {
                                  if( c === 'ambito' && String ( scopes.filter( el => el.id === row[c] )[0]?.nome ).toLowerCase().includes(filter.toLowerCase()) ) { f = true }
                                  else if( c === 'tipologia' && String ( acts.filter( el => el.id === row[c] )[0]?.nome ).toLowerCase().includes(filter.toLowerCase()) ) { f = true }
                                }
                            })

                            let tmp = []

                            tmp = tmp.concat(row.commi)

                            tmp = tmp.concat(row.articoli)
                            for (let art of row.articoli) tmp = tmp.concat(art.commi)

                            tmp = tmp.concat(row.capi)
                            for (let head of row.capi) {
                              tmp = tmp.concat(head.commi)
                              for (let art of head.articoli) tmp = tmp.concat(art.commi)
                            }
                            
                            tmp = tmp.concat(row.titoli)
                            for (let title of row.titoli) {
                              tmp = tmp.concat(title.commi)
                              for (let art of title.articoli) tmp = tmp.concat(art.commi)
                              for (let head of title.capi) {
                                tmp = tmp.concat(head.commi)
                                for (let art of head.articoli) tmp = tmp.concat(art.commi)
                              }
                            }

                            if(row.allegato !== null) tmp.push(row.allegato)

                            for(let t of tmp) {
                              if ( t !== undefined && t.descrizione !== undefined && String(t.descrizione).toLowerCase().includes(filter.toLowerCase()) ) {
                                f = true
                              }
                            }

                            return(
                                <>
                                    {   filter !== '' 
                                        ? f && el
                                        : el
                                    }
                                </>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>

        <Dialog
          open={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
        >
          <DialogTitle>
            Sei sicuro?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Sei sicuro di voler eliminare in maniera definitva la riga selezionata? L'azione è irreversibili e tutti i dati collegati alla riga verranno persi
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button 
              color="error"
              onClick={() => {
                handleDeleteRow()
              }}
            >Elimina riga</Button>
            <Button 
              color="success"
              onClick={() => {
                handleCloseDeleteDialog()
              }}
            >Chiudi</Button>
          </DialogActions>
        </Dialog>
      </>
  );
}

function Row(props) {

    const { scopes, acts, clients, isAddingRow, section, depth, filter, lawClient } = props;
    const [row, setRow] = React.useState(props.row);
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
      setRow(props.row)
    }, [props.row])

    const handleSaveNewRow = () => {
      if( !isAbleToManageRow(userRole, lawClient) ) return
      console.log('Saving new row', row)
      props.setIsAddingRow(false)

      let newRow = { ...row }
      delete newRow.titoli
      delete newRow.capi
      delete newRow.articoli
      delete newRow.commi
      delete newRow.id

      fetch(`${config.API_REPOSITORY}/laws/addLaw.php`, {
        method: 'POST',
        body: JSON.stringify(newRow),
      })
      .then(response => response.json())
      .then(data => {
        console.log('Post result:', data);
        if ( !data ) alert('Errore durante la modifica. Aggiornare la pagina e riprovare')
        else props.reloadRows()
      })

    }

    const handleSaveChanges = (data, column) => {
      if( !isAbleToManageRow(userRole, lawClient) ) return
      console.log(`Saving changes (${row.id}):`, column, '=>', data)

      let newRow = { ...row }
      newRow[column] = data
      setRow(newRow)

      fetch(`${config.API_REPOSITORY}/laws/modifyLaw.php`, {
        method: 'POST',
        body: JSON.stringify({
          id: row.id,
          column: column,
          value: data
        }),
      })
      .then(response => response.json())
      .then(data => {
        console.log('Post result:', data);
        if ( !data ) alert('Errore durante la modifica. Aggiornare la pagina e riprovare')
      })

    }

    const handleDeleteRow = () => {
      if( !isAbleToManageRow(userRole, lawClient) ) return
      console.log('Deleting row', row)

      props.setDeleteId(row.id)
      props.setDeleteEndpoint(`${config.API_REPOSITORY}/laws/deleteLaw.php`)
    }

    const uploadInputRef = React.useRef(null);
    const handleSelectDocument = () => {
      if( !isAbleToManageRow(userRole, lawClient) ) return
      console.log('Select file to upload')
      uploadInputRef.current && uploadInputRef.current.click()
    }

    const handleUploadDocument = (event) => {
      if( !isAbleToManageRow(userRole, lawClient) ) return
      let file = event.target.files[0]
      console.log('Uploading file', file)
      
      let data = new FormData()
      data.append('id', row.id)
      data.append('file', file)

      fetch(`${config.API_REPOSITORY}/docs/uploadDocument.php`, {
        method: 'POST',
        body: data
      })
      .then(response => response.json())
      .then(data => {
        console.log('Post result:', data);
        if ( !data ) alert('Errore durante l\'upload. Aggiornare la pagina e riprovare')
        else props.reloadRows()
      })

    }

    const handleDeleteDocument = () => {
      if( !isAbleToManageRow(userRole, lawClient) ) return
      console.log('Deleting document', row.documento)

      fetch(`${config.API_REPOSITORY}/docs/deleteDocument.php`, {
        method: 'POST',
        body: JSON.stringify({
          id: row.id
        }),
      })
      .then(response => response.json())
      .then(data => {
        console.log('Post result:', data);
        if ( !data ) alert('Errore durante la modifica. Aggiornare la pagina e riprovare')
        else props.reloadRows()
      })
    }

    const handleChangeApplicability = (id_cliente, id_legge, value) => {
      if( !isAbleToManageRow(userRole) ) return
      console.log('Updating applicability', row.identificativo)

      fetch(`${config.API_USERS}/modifyApplicability.php`, {
      method: 'POST',
      body: JSON.stringify({
          id_cliente: id_cliente,
          id_legge: id_legge,
          value: value
      }),
      })
      .then(response => response.json())
      .then(data => {
          console.log('Post result:', data);
          if ( !data ) alert('Errore durante la modifica. Aggiornare la pagina e riprovare')
          else props.reloadRows()
      })
    }

    return (
      <React.Fragment>
        <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <StyledTableCell>
            {
              row.id !== 0 &&
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            }
          </StyledTableCell>
          <StyledTableCell align="center">
            <EditableLabel
              style={{ color: blue[800], fontSize: '1.15em', fontWeight: 'bolder', whiteSpace: 'nowrap'}}
              initialValue={row.identificativo}
              onBlur={value => { 
                if(isAddingRow === false || row.id !== 0) { handleSaveChanges(value, "identificativo") }
                else { props.changeNewRow(value, 'identificativo') }  
              }}
              editable={isAbleToManageRow(userRole, lawClient)}
            />
          </StyledTableCell>
          <StyledTableCell align="center">
            <EditableLabel
              style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem'}}
              initialValue={row.titolo}
              onBlur={value => { 
                if(isAddingRow === false || row.id !== 0) { handleSaveChanges(value, "titolo") }
                else { props.changeNewRow(value, 'titolo') }  
              }}
              editable={isAbleToManageRow(userRole, lawClient)}
            />
          </StyledTableCell>
          <StyledTableCell align="center">
            <EditableSelect
              style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem'}}
              initialValue={row.ambito}
              onBlur={value => { 
                if(isAddingRow === false || row.id !== 0) { handleSaveChanges(value, 'ambito') }
                else { props.changeNewRow(value, 'ambito') }  
              }}
              options={scopes}
              editable={isAbleToManageRow(userRole, lawClient)}
            />
          </StyledTableCell>
          <StyledTableCell align="center">
            <EditableSelect
              style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem'}}
              initialValue={row.tipologia}
              onBlur={value => { 
                if(isAddingRow === false || row.id !== 0) { handleSaveChanges(value, 'tipologia') }
                else { props.changeNewRow(value, 'tipologia') }  
              }}
              options={acts}
              editable={isAbleToManageRow(userRole, lawClient)}
            />
          </StyledTableCell>
          <StyledTableCell align="center">
              <EditableDate 
                style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem'}}
                initialValue={row.entrata}
                onBlur={value => { 
                  if(isAddingRow === false || row.id !== 0) { handleSaveChanges(value, 'entrata') }
                  else { props.changeNewRow(value, 'entrata') }  
                }}
                editable={isAbleToManageRow(userRole, lawClient)}
              />
          </StyledTableCell>
          <StyledTableCell align="center">
            {
              row.documento === null && row.link === null
              ?
                <>
                  {
                    row.id === 0 || !isAbleToManageRow(userRole, lawClient)
                    ?
                    <>
                      <IconButton disabled>
                        <AddIcon />
                      </IconButton>
                      <IconButton disabled>
                        <AddLinkIcon />
                      </IconButton>
                    </>
                    :
                    <div style={{ display: 'flex', justifyContent: 'center'}}>
                      <IconButton>
                        <AddIcon 
                          sx={{ color: 'green' }}
                          onClick={handleSelectDocument}
                        />
                        <input
                          type="file"
                          accept="application/pdf"
                          ref={uploadInputRef}
                          onChange={handleUploadDocument}
                          hidden
                        />
                      </IconButton>
                      <EditableLabel
                        initialValue={
                          <IconButton>
                            <AddLinkIcon
                              sx={{ color: 'blue' }}
                            />
                          </IconButton> 
                        }
                        onBlur={value => {
                          if(value !== '') {
                            if(isAddingRow === false || row.id !== 0) { handleSaveChanges(value, "link") }
                            else { props.changeNewRow(value, 'link') }
                          }
                        }}
                        editable={isAbleToManageRow(userRole, lawClient)}
                      />
                      
                    </div>
                  }
                </>
              :
              row.documento !== null
                ?
                <>
                  {/*<IconButton color="secondary">
                    <VisibilityIcon 
                      onClick={() => {
                        let a = document.createElement('a')
                        a.href = `${config.LAWS_FOLDER}/${row.id}`
                        a.target = "_blank"
                        a.click()
                      }}
                    />
                  </IconButton>*/}
                  <IconButton color="success">
                    <DownloadIcon
                      onClick={() => {
                        let a = document.createElement('a')
                        a.href = `${config.API_REPOSITORY}/docs/downloadDocument.php?id=${row.id}`
                        a.target = "_blank"
                        a.click()
                      }}
                    />
                  </IconButton>
                  {/*<IconButton color="primary">
                    <EditIcon 

                    />
                  </IconButton>*/}
                  { (lawClient !== 0 || userRole === -1 || userRole === 1) &&
                  <IconButton color="error" disabled={!isAbleToManageRow(userRole, lawClient)}>
                    <DeleteIcon
                      onClick={handleDeleteDocument}
                    />
                  </IconButton>
                  }
                </>
                :
                <>
                  <IconButton color="primary">
                    <OpenInNewIcon
                      onClick={() => {
                        let a = document.createElement('a')
                        a.href = row.link
                        a.target = "_blank"
                        a.click()
                      }}
                    />
                  </IconButton>
                  { (lawClient !== 0 || userRole === -1 || userRole === 1) &&
                  <IconButton color="error" disabled={!isAbleToManageRow(userRole, lawClient)}>
                    <DeleteIcon
                      onClick={() => handleSaveChanges(null, "link")}
                    />
                  </IconButton>
                  }
                </>
            }
          </StyledTableCell>
          { showClient(userRole) &&
            <StyledTableCell align="center">
              {   ( userRole === -1 || userRole === 1 )
                  ?
                  <EditableSelect
                      style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem'}}
                      initialValue={lawClient}
                      onBlur={value => { 
                        if(isAddingRow === false || row.id !== 0) { handleSaveChanges(value, "cliente") }
                              else { props.changeNewRow(value, 'cliente') }
                      }}
                      options={clients}
                      editable={isAbleToManageRow(userRole, lawClient)}
                  />
                  :
                  <InputLabel style={{ color: 'rgba(0, 0, 0, 0.50)', fontSize: '0.875rem', fontStyle: 'italic'}}>{lawClient !== 0 ? clients.find((el) => { return el.id === lawClient }).nome : 'Globale'}</InputLabel>
              }
            </StyledTableCell>
          }
          {/* showApplicability(userRole) &&
            <StyledTableCell align="center">
                <Checkbox 
                    checked={(row.applicabilita === true || row.applicabilita === 1) ? true : false} 
                    onChange={ event => handleChangeApplicability( userClient, row.id, event.target.checked ) }
                    disabled={showApplicability(userRole) && !(row.cliente !== 0)}
                />
            </StyledTableCell>
          */}
          <StyledTableCell align="center">
            <Checkbox 
                checked={(row.applicabilita === true || row.applicabilita === 1) ? true : false} 
                onChange={ event => handleChangeApplicability( userClient, row.id, event.target.checked ) }
                disabled={row.cliente == 0}
            />
          </StyledTableCell>
          <StyledTableCell align="center">
            {
              row.id !== 0
              ?
              <div style={{ display: 'flex', justifyContent: 'center'}}>
                { (lawClient !== 0 || userRole === -1 || userRole === 1) &&
                <IconButton color="error" disabled={!isAbleToManageRow(userRole, lawClient)}>
                  <DeleteIcon
                    onClick={handleDeleteRow}
                  />
                </IconButton>
                }
                { showAssessment(userRole) && (row.applicabilita === true || row.applicabilita === 1) &&
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => {
                        props.setLegalId(row.id)
                        props.setClientId(userClient)
                    }}
                    sx={{ my: 2, color: 'white', display: 'block', textDecoration: 'none' }}
                    as={Link} 
                    to={`/site/assessment-managment`}
                >
                    Assessment
                </Button> }
              </div>
              :
              <IconButton color="success" disabled={!isAbleToManageRow(userRole, lawClient)}>
                <SaveIcon
                  onClick={handleSaveNewRow}
                />
              </IconButton>
            }
          </StyledTableCell>
        </StyledTableRow>
        <TableRow>
          <StyledTableCell style={{ paddingBottom: 0, paddingTop: open ? '15px' : 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              { ((depth === depthLevels.title && section === sectionViewTypes.title && 
                (row.capi === undefined || row.capi.length === 0) && 
                (row.articoli === undefined || row.articoli.length === 0) && 
                (row.commi === undefined || row.commi.length === 0) &&
                (row.sentenza === undefined || row.sentenza === null) &&
                (row.allegato === undefined || row.allegato === null)) || 
                (row.titoli !== undefined && row.titoli.length > 0) ) &&
                <TitlesTable
                  lawId={row.id}
                  titoli={row.titoli.length > 0 ? row.titoli : []}
                  filter={filter}

                  section={props.section}
                  depth={props.depth}

                  lawClient={lawClient}

                  handlePreviousSection={props.handlePreviousSection}
                  handleNextSection={props.handleNextSection}
                  setDepth={props.setDepth}
                  setSection={props.setSection}
                  reloadRows={props.reloadRows}

                  setDeleteId={props.setDeleteId}
                  setDeleteEndpoint={props.setDeleteEndpoint}
                />
              }
              { ((depth === depthLevels.title && section === sectionViewTypes.head && 
                (row.articoli === undefined || row.articoli.length === 0) && 
                (row.commi === undefined || row.commi.length === 0) &&
                (row.sentenza === undefined || row.sentenza === null) &&
                (row.allegato === undefined || row.allegato === null)) || 
                (row.capi !== undefined && row.capi.length > 0) ) &&
                <HeadsTable
                  lawId={row.id}
                  capi={row.capi.length > 0 ? row.capi : []}
                  filter={filter}

                  section={props.section}
                  depth={props.depth}

                  lawClient={lawClient}

                  handlePreviousSection={props.handlePreviousSection}
                  handleNextSection={props.handleNextSection}
                  setDepth={props.setDepth}
                  setSection={props.setSection}
                  reloadRows={props.reloadRows}

                  setDeleteId={props.setDeleteId}
                  setDeleteEndpoint={props.setDeleteEndpoint}
                />
              }
              { ( (depth === depthLevels.title && section === sectionViewTypes.article && 
                (row.commi === undefined || row.commi.length === 0) &&
                (row.sentenza === undefined || row.sentenza === null) &&
                (row.allegato === undefined || row.allegato === null)) || 
                (row.articoli !== undefined && row.articoli.length > 0 ) ) &&
                <ArticlesTable
                  lawId={row.id}
                  articoli={row.articoli.length > 0 ? row.articoli : []}
                  filter={filter}

                  section={props.section}
                  depth={props.depth}

                  lawClient={lawClient}

                  handlePreviousSection={props.handlePreviousSection}
                  handleNextSection={props.handleNextSection}
                  setDepth={props.setDepth}
                  setSection={props.setSection}
                  reloadRows={props.reloadRows}

                  setDeleteId={props.setDeleteId}
                  setDeleteEndpoint={props.setDeleteEndpoint}
                />
              }
              { ( (depth === depthLevels.title && section === sectionViewTypes.paragraph &&
                (row.sentenza === undefined || row.sentenza === null) &&
                (row.allegato === undefined || row.allegato === null) ) || 
                (row.commi !== undefined && row.commi.length > 0) ) &&
                <ParagraphesTable
                  lawId={row.id}
                  commi={row.commi.length > 0 ? row.commi : []}
                  filter={filter}

                  section={props.section}
                  depth={props.depth}

                  lawClient={lawClient}

                  handlePreviousSection={props.handlePreviousSection}
                  handleNextSection={props.handleNextSection}
                  setDepth={props.setDepth}
                  setSection={props.setSection}
                  reloadRows={props.reloadRows}

                  setDeleteId={props.setDeleteId}
                  setDeleteEndpoint={props.setDeleteEndpoint}
                />
              }
              { (depth === depthLevels.title && section === sectionViewTypes.attached || (row.allegato !== undefined && row.allegato !== null && row.allegato.descrizione !== null) ) &&
                <Attached
                  lawId={row.id}
                  allegato={row.allegato !== null ? row.allegato : {descrizione: null}}
                  filter={filter}

                  section={props.section}
                  depth={props.depth}

                  handlePreviousSection={props.handlePreviousSection}
                  handleNextSection={props.handleNextSection}
                  setDepth={props.setDepth}
                  setSection={props.setSection}
                  reloadRows={props.reloadRows}

                  setDeleteId={props.setDeleteId}
                  setDeleteEndpoint={props.setDeleteEndpoint}
                />
              }
              { (depth === depthLevels.title && section === sectionViewTypes.sentence || (row.sentenza !== undefined && row.sentenza !== null && row.sentenza.descrizione !== null) ) &&
                <Sentence
                  lawId={row.id}
                  sentenza={row.sentenza !== null ? row.sentenza : {descrizione: null}}
                  filter={filter}

                  section={props.section}
                  depth={props.depth}

                  handlePreviousSection={props.handlePreviousSection}
                  handleNextSection={props.handleNextSection}
                  setDepth={props.setDepth}
                  setSection={props.setSection}
                  reloadRows={props.reloadRows}

                  setDeleteId={props.setDeleteId}
                  setDeleteEndpoint={props.setDeleteEndpoint}
                />
              }
            </Collapse>
          </StyledTableCell>
        </TableRow>
      </React.Fragment>
    );
}

function TitlesTable(props) {

  props.titoli.sort(compareFn)

  const { section, depth, lawId, filter, lawClient } = props

  const [titles, setTitles] = React.useState(props.titoli)
  const [isAddingTitle, setIsAddingTitle] = React.useState(false)

  React.useEffect(() => {
    setTitles(props.titoli)
  }, [props.titoli])

  const handleCreateNewTitle = () => {
    if( !isAbleToManageRow(userRole, lawClient) ) return
    let newTitles = [...titles]
    newTitles.push(createBlankTitle())
    setTitles(newTitles)
    setIsAddingTitle(true)
  }

  const handleDiscardNewTitle = () => {
    if( !isAbleToManageRow(userRole, lawClient) ) return
    let newTitles = [...titles]
    newTitles.discardNewRow()
    setTitles(newTitles)
    setIsAddingTitle(false)
  }

  const handleSaveNewTitle = () => {
    if( !isAbleToManageRow(userRole, lawClient) ) return
    let newTitle = titles.find((el) => { return el.id === 0 } )
    delete newTitle.id
    delete newTitle.capi
    delete newTitle.articoli
    delete newTitle.commi
    newTitle.id_legge = lawId
    console.log('Saving new title', newTitle)
    setIsAddingTitle(false)

    fetch(`${config.API_REPOSITORY}/titles/addTitle.php`, {
      method: 'POST',
      body: JSON.stringify(newTitle),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Post result:', data);
      if ( !data ) alert('Errore durante la modifica. Aggiornare la pagina e riprovare')
      else props.reloadRows()
    })

  }

  const handleSaveChanges = (id, data, column) => {
    if( !isAbleToManageRow(userRole, lawClient) ) return
    console.log(`Saving changes (${id}):`, column, '=>', data)
    let newTitles = [...titles]
    for(let i=0; i<newTitles.length; i++) { if( newTitles[i].id === id ) newTitles[i][column] = data}
    setTitles(newTitles)

    fetch(`${config.API_REPOSITORY}/titles/modifyTitle.php`, {
      method: 'POST',
      body: JSON.stringify({
        id: id,
        column: column,
        value: data
      }),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Post result:', data);
      if ( !data ) alert('Errore durante la modifica. Aggiornare la pagina e riprovare')
    })

    console.log('Row saved')
  }

  const handleDeleteTitle = (id) => {
    if( !isAbleToManageRow(userRole, lawClient) ) return
    console.log('Deleting title', titles.find((el) => { return el.id === id } ))

    props.setDeleteId(id)
    props.setDeleteEndpoint(`${config.API_REPOSITORY}/titles/deleteTitle.php`)
  }

  const handleSaveChangesNewTitle = (data, column) => {
    if( !isAbleToManageRow(userRole, lawClient) ) return
    let newTitles = [...titles]
    newTitles.find((el) => { return el.id === 0 })[column] = data
    setTitles(newTitles)
    console.log('New title changed')
  }

  return(
    <Box sx={{ margin: 1 }}>
      <Typography variant="h6" gutterBottom component="div" sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          {
            isAddingTitle === false
            ?
            <IconButton
              disabled={!isAbleToManageRow(userRole, lawClient)}
            >
              <AddCircleOutlineIcon
                onClick={handleCreateNewTitle}
              />
            </IconButton>
            :
            <IconButton
              disabled={!isAbleToManageRow(userRole, lawClient)}
            >
              <RemoveCircleOutlineIcon
                onClick={handleDiscardNewTitle}
              />
            </IconButton>
          }
          
          Titoli / testo
          { titles.length === 0 &&
            <IconButton
              disabled={!isAbleToManageRow(userRole, lawClient)}
            >
              <RedoIcon
                onClick={props.handleNextSection}
              />
            </IconButton>
          }
      </Typography>
      <Table size="small" aria-label="purchases">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="center">Nome</TableCell>
            <TableCell align="center">Descrizione</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {titles.map((titolo) => {

            let el =
            <TitleRow
              key={`Title-${titolo.id}`} 
              titolo={titolo}
              isAddingTitle={isAddingTitle}
              filter={filter}

              section={props.section}
              depth={props.depth}

              lawClient={lawClient}

              handleSaveNewTitle={handleSaveNewTitle}
              handleSaveChanges={handleSaveChanges}
              handleSaveChangesNewTitle={handleSaveChangesNewTitle}
              handleDeleteTitle={handleDeleteTitle}

              handlePreviousSection={props.handlePreviousSection}
              handleNextSection={props.handleNextSection}
              setDepth={props.setDepth}
              setSection={props.setSection}
              reloadRows={props.reloadRows}

              setDeleteId={props.setDeleteId}
              setDeleteEndpoint={props.setDeleteEndpoint}
            />

            if(titolo.articoli === undefined || titolo.capi === undefined || titolo.commi === undefined) return el

            let f = false
            let tmp = [titolo]

            tmp = tmp.concat(titolo.commi)

            tmp = tmp.concat(titolo.articoli)
            for (let art of titolo.articoli) tmp = tmp.concat(art.commi)

            tmp = tmp.concat(titolo.capi)
            for (let head of titolo.capi) {
              tmp = tmp.concat(head.commi)
              for (let art of head.articoli) tmp = tmp.concat(art.commi)
            }

            if(titolo.allegato !== null) tmp.push(titolo.allegato)

            for(let t of tmp) {
              if ( t !== undefined && t.descrizione !== undefined && String(t.descrizione).toLowerCase().includes(filter.toLowerCase()) ) {
                f = true
              }
            }

            return(
                <>
                    {   filter !== '' 
                        ? f && el
                        : el
                    }
                </>
            )
            return el
          })}
        </TableBody>
      </Table>
    </Box>
  );

}

function TitleRow (props) {

  const { isAddingTitle, section, depth, filter, lawClient } = props
  const [titolo, setTitolo] = React.useState(props.titolo)

  React.useEffect(() => {
    setTitolo(props.titolo)
  }, [props.titolo])

  const [open, setOpen] = React.useState(false)

  return (
    <>
      <TableRow>
        <TableCell>
        { titolo.id !== 0 &&
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setOpen(!open)
                props.setDepth(depthLevels.head)
                props.setSection(sectionViewTypes.head)
              }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
        }
        </TableCell>
        <TableCell align="center">
          <EditableLabel
            style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem', fontWeight: 'bold', fontStyle: 'italic', whiteSpace: 'nowrap'}}
            initialValue={titolo.nome}
            onBlur={value => { 
              if(isAddingTitle === false) { props.handleSaveChanges(titolo.id, value, 'nome') }
              else { props.handleSaveChangesNewTitle(value, 'nome') }  
            }}
            editable={isAbleToManageRow(userRole, lawClient)}
          />
        </TableCell>
        <TableCell align="center">
          <EditableLabel
            style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem'}}
            initialValue={titolo.descrizione}
            onBlur={value => { 
              if(isAddingTitle === false) { props.handleSaveChanges(titolo.id, value, 'descrizione') }
              else { props.handleSaveChangesNewTitle(value, 'descrizione') }  
            }}
            editable={isAbleToManageRow(userRole, lawClient)}
          />
        </TableCell>
        <TableCell align="right">
          {
            titolo.id !== 0
            ?
            (lawClient !== 0 || userRole === -1 || userRole === 1) &&
            <IconButton color="error" disabled={!isAbleToManageRow(userRole, lawClient)}>
              <DeleteIcon
                onClick={() => props.handleDeleteTitle(titolo.id)}
              />
            </IconButton>
            :
            <IconButton color="success" disabled={!isAbleToManageRow(userRole, lawClient)}>
              <SaveIcon
                onClick={props.handleSaveNewTitle}
              />
            </IconButton>
          }
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: open ? '15px' : 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
              { ( (depth === depthLevels.head && section === sectionViewTypes.head && 
                (titolo.articoli === undefined || titolo.articoli.length === 0) && 
                (titolo.commi === undefined || titolo.commi.length === 0) &&
                (titolo.allegato === undefined || titolo.allegato === null) ) 
                || (titolo.capi !== undefined && titolo.capi.length > 0) ) &&
                <HeadsTable
                  titleId={titolo.id}
                  capi={titolo.capi !== undefined && titolo.capi.length > 0 ? titolo.capi : []}
                  filter={filter}

                  section={props.section}
                  depth={props.depth}

                  lawClient={lawClient}

                  handlePreviousSection={props.handlePreviousSection}
                  handleNextSection={props.handleNextSection}
                  setDepth={props.setDepth}
                  setSection={props.setSection}
                  reloadRows={props.reloadRows}

                  setDeleteId={props.setDeleteId}
                  setDeleteEndpoint={props.setDeleteEndpoint}
                />
              }
              { ((depth === depthLevels.head && section === sectionViewTypes.article && 
                  (titolo.commi === undefined || titolo.commi.length === 0) &&
                  (titolo.allegato === undefined || titolo.allegato === null)) || 
                  (titolo.articoli !== undefined && titolo.articoli.length > 0) ) &&
                <ArticlesTable
                  titleId={titolo.id}
                  articoli={titolo.articoli !== undefined && titolo.articoli.length > 0 ? titolo.articoli : []}
                  filter={filter}

                  section={props.section}
                  depth={props.depth}

                  lawClient={lawClient}

                  handlePreviousSection={props.handlePreviousSection}
                  handleNextSection={props.handleNextSection}
                  setDepth={props.setDepth}
                  setSection={props.setSection}
                  reloadRows={props.reloadRows}

                  setDeleteId={props.setDeleteId}
                  setDeleteEndpoint={props.setDeleteEndpoint}
                />
              }
              { (depth === depthLevels.head && section === sectionViewTypes.paragraph && (titolo.allegato === undefined || titolo.allegato === null) || (titolo.commi !== undefined && titolo.commi.length > 0) ) &&
                <ParagraphesTable
                  titleId={titolo.id}
                  commi={titolo.commi !== undefined && titolo.commi.length > 0 ? titolo.commi : []}
                  filter={filter}

                  section={props.section}
                  depth={props.depth}

                  lawClient={lawClient}

                  handlePreviousSection={props.handlePreviousSection}
                  handleNextSection={props.handleNextSection}
                  setDepth={props.setDepth}
                  setSection={props.setSection}
                  reloadRows={props.reloadRows}

                  setDeleteId={props.setDeleteId}
                  setDeleteEndpoint={props.setDeleteEndpoint}
                />
              }
              { (depth === depthLevels.head && section === sectionViewTypes.attached || (titolo.allegato !== undefined && titolo.allegato !== null && titolo.allegato.descrizione !== null) ) &&
                <Attached
                  titleId={titolo.id}
                  allegato={titolo.allegato !== null ? titolo.allegato : {descrizione: null}}
                  filter={filter}

                  section={props.section}
                  depth={props.depth}

                  lawClient={lawClient}

                  handlePreviousSection={props.handlePreviousSection}
                  handleNextSection={props.handleNextSection}
                  setDepth={props.setDepth}
                  setSection={props.setSection}
                  reloadRows={props.reloadRows}

                  setDeleteId={props.setDeleteId}
                  setDeleteEndpoint={props.setDeleteEndpoint}
                />
              }
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )

}

function HeadsTable(props) {

  props.capi.sort(compareFn)

  const { section, depth, lawId, titleId, filter, lawClient } = props

  const [heads, setHeads] = React.useState(props.capi)
  const [isAddingHead, setIsAddingHead] = React.useState(false)

  React.useEffect(() => {
    setHeads(props.capi)
  }, [props.capi])

  const handleCreateNewHead = () => {
    if( !isAbleToManageRow(userRole, lawClient) ) return
    let newHeads = [...heads]
    newHeads.push(createBlankHead())
    setHeads(newHeads)
    setIsAddingHead(true)
  }

  const handleDiscardNewHead = () => {
    if( !isAbleToManageRow(userRole, lawClient) ) return
    let newHeads = [...heads]
    newHeads.discardNewRow()
    setHeads(newHeads)
    setIsAddingHead(false)
  }

  const handleSaveNewHead = () => {
    if( !isAbleToManageRow(userRole, lawClient) ) return
    let newHead = heads.find((el) => { return el.id === 0 } )
    delete newHead.id
    delete newHead.articoli
    delete newHead.commi
    newHead.id_legge = lawId !== undefined ? lawId : null
    newHead.id_titolo = titleId !== undefined ? titleId : null
    console.log('Saving new head', newHead)
    setIsAddingHead(false)

    fetch(`${config.API_REPOSITORY}/heads/addHead.php`, {
      method: 'POST',
      body: JSON.stringify(newHead),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Post result:', data);
      if ( !data ) alert('Errore durante la modifica. Aggiornare la pagina e riprovare')
      else props.reloadRows()
    })

  }

  const handleSaveChanges = (id, data, column) => {
    if( !isAbleToManageRow(userRole, lawClient) ) return
    console.log(`Saving changes (${id}):`, column, '=>', data)
    let newHeads = [...heads]
    for(let i=0; i<newHeads.length; i++) { if( newHeads[i].id === id ) newHeads[i][column] = data}
    setHeads(newHeads)

    fetch(`${config.API_REPOSITORY}/heads/modifyHead.php`, {
      method: 'POST',
      body: JSON.stringify({
        id: id,
        column: column,
        value: data
      }),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Post result:', data);
      if ( !data ) alert('Errore durante la modifica. Aggiornare la pagina e riprovare')
    })

    console.log('Row saved')
  }

  const handleDeleteHead = (id) => {
    if( !isAbleToManageRow(userRole, lawClient) ) return
    console.log('Deleting head', heads.find((el) => { return el.id === id } ))

    props.setDeleteId(id)
    props.setDeleteEndpoint(`${config.API_REPOSITORY}/heads/deleteHead.php`)
  }

  const handleSaveChangesNewHead = (data, column) => {
    if( !isAbleToManageRow(userRole, lawClient) ) return
    let newHeads = [...heads]
    newHeads.find((el) => { return el.id === 0 })[column] = data
    setHeads(newHeads)
    console.log('New article changed')
  }

  return(
    <Box sx={{ margin: 1 }}>
      <Typography variant="h6" gutterBottom component="div" sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          {
            isAddingHead === false
            ?
            <IconButton
              disabled={!isAbleToManageRow(userRole, lawClient)}
            >
              <AddCircleOutlineIcon
                onClick={handleCreateNewHead}
              />
            </IconButton>
            :
            <IconButton
              disabled={!isAbleToManageRow(userRole, lawClient)}
            >
              <RemoveCircleOutlineIcon
                onClick={handleDiscardNewHead}
              />
            </IconButton>
          }
          
          Capi / testo
          { heads.length === 0 && depth < depthLevels.head &&
            <IconButton
              disabled={!isAbleToManageRow(userRole, lawClient)}
            >
              <UndoIcon
                onClick={props.handlePreviousSection}
              />
            </IconButton>
          }
          { heads.length === 0 &&
            <IconButton
              disabled={!isAbleToManageRow(userRole, lawClient)}
            >
              <RedoIcon
                onClick={props.handleNextSection}
              />
            </IconButton>
          }
      </Typography>
      <Table size="small" aria-label="purchases">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="center">Nome</TableCell>
            <TableCell align="center">Descrizione</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {heads.map((capo) => {
            let el =
            <HeadRow
              key={`Head-${capo.id}`} 
              capo={capo}
              isAddingHead={isAddingHead}
              filter={filter}

              section={section}
              depth={depth}

              lawClient={lawClient}

              handleSaveNewHead={handleSaveNewHead}
              handleSaveChanges={handleSaveChanges}
              handleSaveChangesNewHead={handleSaveChangesNewHead}
              handleDeleteHead={handleDeleteHead}

              handlePreviousSection={props.handlePreviousSection}
              handleNextSection={props.handleNextSection}
              setDepth={props.setDepth}
              setSection={props.setSection}
              reloadRows={props.reloadRows}

              setDeleteId={props.setDeleteId}
              setDeleteEndpoint={props.setDeleteEndpoint}
            />

            if(capo.articoli === undefined || capo.commi === undefined) return el

            let f = false
            let tmp = [capo]

            tmp = tmp.concat(capo.commi)

            tmp = tmp.concat(capo.articoli)
            for (let art of capo.articoli) tmp = tmp.concat(art.commi)

            if(capo.allegato !== null) tmp.push(capo.allegato)

            for(let t of tmp) {
              if ( t !== undefined && t.descrizione !== undefined && String(t.descrizione).toLowerCase().includes(filter.toLowerCase()) ) {
                f = true
              }
            }

            return(
                <>
                    {   filter !== '' 
                        ? f && el
                        : el
                    }
                </>
            )
          })}
        </TableBody>
      </Table>
    </Box>
  );

}

function HeadRow (props) {

  const { isAddingHead, section, depth, filter, lawClient } = props
  const [capo, setCapo] = React.useState(props.capo)

  React.useEffect(() => {
    setCapo(props.capo)
  }, [props.capo])

  const [open, setOpen] = React.useState(false)

  return (
    <>
      <TableRow>
        <TableCell>
        { capo.id !== 0 &&
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setOpen(!open)
                props.setDepth(depthLevels.article)
                props.setSection(sectionViewTypes.article)
              }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
        }
        </TableCell>
        <TableCell align="center">
          <EditableLabel
            style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem', fontWeight: 'bold', fontStyle: 'italic', whiteSpace: 'nowrap'}}
            initialValue={capo.nome}
            onBlur={value => { 
              if(isAddingHead === false) { props.handleSaveChanges(capo.id, value, 'nome') }
              else { props.handleSaveChangesNewHead(value, 'nome') }  
            }}
            editable={isAbleToManageRow(userRole, lawClient)}
          />
        </TableCell>
        <TableCell align="center">
          <EditableLabel
            style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem'}}
            initialValue={capo.descrizione}
            onBlur={value => { 
              if(isAddingHead === false) { props.handleSaveChanges(capo.id, value, 'descrizione') }
              else { props.handleSaveChangesNewHead(value, 'descrizione') }  
            }}
            editable={isAbleToManageRow(userRole, lawClient)}
          />
        </TableCell>
        <TableCell align="right">
          {
            capo.id !== 0
            ?
            (lawClient !== 0 || userRole === -1 || userRole === 1) &&
            <IconButton color="error" disabled={!isAbleToManageRow(userRole, lawClient)}>
              <DeleteIcon
                onClick={() => props.handleDeleteHead(capo.id)}
              />
            </IconButton>
            :
            <IconButton color="success" disabled={!isAbleToManageRow(userRole, lawClient)}>
              <SaveIcon
                onClick={props.handleSaveNewHead}
              />
            </IconButton>
          }
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: open ? '15px' : 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
              { ((depth === depthLevels.article && section === sectionViewTypes.article && (capo.commi === undefined || capo.commi.length === 0) && (capo.allegato === undefined || capo.allegato === null) ) || 
                (capo.articoli !== undefined && capo.articoli.length > 0 ) ) &&
                <ArticlesTable
                  headId={capo.id}
                  articoli={capo.articoli !== undefined && capo.articoli.length > 0 ? capo.articoli : []}
                  filter={filter}

                  section={section}
                  depth={depth}

                  lawClient={lawClient}

                  handlePreviousSection={props.handlePreviousSection}
                  handleNextSection={props.handleNextSection}
                  setDepth={props.setDepth}
                  setSection={props.setSection}
                  reloadRows={props.reloadRows}

                  setDeleteId={props.setDeleteId}
                  setDeleteEndpoint={props.setDeleteEndpoint}
                />
              }
              { (depth === depthLevels.article && section === sectionViewTypes.paragraph && (capo.allegato === undefined || capo.allegato === null) || (capo.commi !== undefined && capo.commi.length > 0 ) ) &&
                <ParagraphesTable
                  headId={capo.id}
                  commi={capo.commi !== undefined && capo.commi.length > 0 ? capo.commi : []}
                  filter={filter}

                  section={section}
                  depth={depth}

                  lawClient={lawClient}

                  handlePreviousSection={props.handlePreviousSection}
                  handleNextSection={props.handleNextSection}
                  setDepth={props.setDepth}
                  setSection={props.setSection}
                  reloadRows={props.reloadRows}

                  setDeleteId={props.setDeleteId}
                  setDeleteEndpoint={props.setDeleteEndpoint}
                />
              }
              { (depth === depthLevels.article && section === sectionViewTypes.attached || (capo.allegato !== undefined && capo.allegato !== null && capo.allegato.descrizione !== null) ) &&
                <Attached
                  headId={capo.id}
                  allegato={capo.allegato !== null ? capo.allegato : {descrizione: null}}
                  filter={filter}

                  section={props.section}
                  depth={props.depth}

                  lawClient={lawClient}

                  handlePreviousSection={props.handlePreviousSection}
                  handleNextSection={props.handleNextSection}
                  setDepth={props.setDepth}
                  setSection={props.setSection}
                  reloadRows={props.reloadRows}

                  setDeleteId={props.setDeleteId}
                  setDeleteEndpoint={props.setDeleteEndpoint}
                />
              }
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )

}

function ArticlesTable(props) {

  props.articoli.sort(compareFn)

  const { lawId, titleId, headId, section, depth, filter, lawClient } = props

  const [articles, setArticles] = React.useState(props.articoli)
  const [isAddingArticle, setIsAddingArticle] = React.useState(false)

  React.useEffect(() => {
    setArticles(props.articoli)
  }, [props.articoli])

  const handleCreateNewArticle = () => {
    if( !isAbleToManageRow(userRole, lawClient) ) return
    let newArticles = [...articles]
    newArticles.push(createBlankArticle())
    setArticles(newArticles)
    setIsAddingArticle(true)
  }

  const handleDiscardNewArticle = () => {
    if( !isAbleToManageRow(userRole, lawClient) ) return
    let newArticles = [...articles]
    newArticles.discardNewRow()
    setArticles(newArticles)
    setIsAddingArticle(false)
  }

  const handleSaveNewArticle = () => {
    if( !isAbleToManageRow(userRole, lawClient) ) return
    let newArticle = articles.find((art) => { return art.id === 0 } )
    delete newArticle.id
    delete newArticle.commi
    newArticle.id_legge = lawId !== undefined ? lawId : null
    newArticle.id_titolo = titleId !== undefined ? titleId : null
    newArticle.id_capo = headId !== undefined ? headId : null
    console.log('Saving new article', newArticle)
    setIsAddingArticle(false)

    fetch(`${config.API_REPOSITORY}/articles/addArticle.php`, {
      method: 'POST',
      body: JSON.stringify(newArticle),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Post result:', data);
      if ( !data ) alert('Errore durante la modifica. Aggiornare la pagina e riprovare')
      else props.reloadRows()
    })

  }

  const handleSaveChanges = (id, data, column) => {
    if( !isAbleToManageRow(userRole, lawClient) ) return
    console.log(`Saving changes (${id}):`, column, '=>', data)
    let newArticles = [...articles]
    for(let i=0; i<newArticles.length; i++) { if( newArticles[i].id === id ) newArticles[i][column] = data}
    setArticles(newArticles)

    fetch(`${config.API_REPOSITORY}/articles/modifyArticle.php`, {
      method: 'POST',
      body: JSON.stringify({
        id: id,
        column: column,
        value: data
      }),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Post result:', data);
      if ( !data ) alert('Errore durante la modifica. Aggiornare la pagina e riprovare')
    })

    console.log('Row saved')
  }

  const handleDeleteArticle = (id) => {
    if( !isAbleToManageRow(userRole, lawClient) ) return
    console.log('Deleting article', articles.find((art) => { return art.id === id } ))

    props.setDeleteId(id)
    props.setDeleteEndpoint(`${config.API_REPOSITORY}/articles/deleteArticle.php`)
  }

  const handleSaveChangesNewArticle = (data, column) => {
    if( !isAbleToManageRow(userRole, lawClient) ) return
    let newArticles = [...articles]
    newArticles.find((article) => { return article.id === 0 })[column] = data
    setArticles(newArticles)
    console.log('New article changed')
  }

  return(
    <Box sx={{ margin: 1 }}>
      <Typography variant="h6" gutterBottom component="div" sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          {
            isAddingArticle === false
            ?
            <IconButton
              disabled={!isAbleToManageRow(userRole, lawClient)}
            >
              <AddCircleOutlineIcon
                onClick={handleCreateNewArticle}
              />
            </IconButton>
            :
            <IconButton
              disabled={!isAbleToManageRow(userRole, lawClient)}
            >
              <RemoveCircleOutlineIcon
                onClick={handleDiscardNewArticle}
              />
            </IconButton>
          }
          
          Articoli / testo
          { articles.length === 0 && depth < depthLevels.article &&
            <IconButton
              disabled={!isAbleToManageRow(userRole, lawClient)}
            >
              <UndoIcon
                onClick={props.handlePreviousSection}
              />
            </IconButton>
          }
          { articles.length === 0 &&
            <IconButton
              disabled={!isAbleToManageRow(userRole, lawClient)}
            >
              <RedoIcon
                onClick={props.handleNextSection}
              />
            </IconButton>
          }
      </Typography>
      <Table size="small" aria-label="purchases">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="center">Nome</TableCell>
            <TableCell align="center">Descrizione</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {articles.map((articolo) => {
            let el =
            <ArticleRow
              key={`Article-${articolo.id}`} 
              articolo={articolo}
              isAddingArticle={isAddingArticle}
              filter={filter}

              section={section}
              depth={depth}

              lawClient={lawClient}

              handleSaveNewArticle={handleSaveNewArticle}
              handleSaveChanges={handleSaveChanges}
              handleSaveChangesNewArticle={handleSaveChangesNewArticle}
              handleDeleteArticle={handleDeleteArticle}

              handlePreviousSection={props.handlePreviousSection}
              handleNextSection={props.handleNextSection}
              setDepth={props.setDepth}
              setSection={props.setSection}
              reloadRows={props.reloadRows}

              setDeleteId={props.setDeleteId}
              setDeleteEndpoint={props.setDeleteEndpoint}
            />

            if(articolo.commi === undefined) return el

            let f = false
            let tmp = [articolo]

            tmp = tmp.concat(articolo.commi)

            if(articolo.allegato !== null) tmp.push(articolo.allegato)

            for(let t of tmp) {
              if ( t !== undefined && t.descrizione !== undefined && String(t.descrizione).toLowerCase().includes(filter.toLowerCase()) ) {
                f = true
              }
            }

            return(
                <>
                    {   filter !== '' 
                        ? f && el
                        : el
                    }
                </>
            )
            
          })}
        </TableBody>
      </Table>
    </Box>
  );

}

function ArticleRow (props) {

  const { isAddingArticle, section, depth, filter, lawClient } = props
  const [articolo, setArticolo] = React.useState(props.articolo)

  React.useEffect(() => {
    setArticolo(props.articolo)
  }, [props.articolo])

  const [open, setOpen] = React.useState(false)

  return (
    <>
      <TableRow>
        <TableCell>
        { articolo.id !== 0 &&
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setOpen(!open)
                props.setDepth(depthLevels.paragraph)
                props.setSection(sectionViewTypes.paragraph)
              }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
        }
        </TableCell>
        <TableCell align="center">
          <EditableLabel
            style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem', fontWeight: 'bold', fontStyle: 'italic', whiteSpace: 'nowrap'}}
            initialValue={articolo.nome}
            onBlur={value => { 
              if(isAddingArticle === false) { props.handleSaveChanges(articolo.id, value, 'nome') }
              else { props.handleSaveChangesNewArticle(value, 'nome') }  
            }}
            editable={isAbleToManageRow(userRole, lawClient)}
          />
        </TableCell>
        <TableCell align="center">
          <EditableLabel
            style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem'}}
            initialValue={articolo.descrizione}
            onBlur={value => { 
              if(isAddingArticle === false) { props.handleSaveChanges(articolo.id, value, 'descrizione') }
              else { props.handleSaveChangesNewArticle(value, 'descrizione') }  
            }}
            editable={isAbleToManageRow(userRole, lawClient)}
          />
        </TableCell>
        <TableCell align="right">
          {
            articolo.id !== 0
            ?
            (lawClient !== 0 || userRole === -1 || userRole === 1) &&
            <IconButton color="error" disabled={!isAbleToManageRow(userRole, lawClient)}>
              <DeleteIcon
                onClick={() => props.handleDeleteArticle(articolo.id)}
              />
            </IconButton>
            :
            <IconButton color="success" disabled={!isAbleToManageRow(userRole, lawClient)}>
              <SaveIcon
                onClick={props.handleSaveNewArticle}
              />
            </IconButton>
          }
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: open ? '15px' : 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            { (depth === depthLevels.paragraph && section === sectionViewTypes.paragraph && (articolo.allegato === undefined || articolo.allegato === null) || (articolo.commi !== undefined && articolo.commi.length > 0 ) ) &&
              <ParagraphesTable
                articleId={articolo.id}
                commi={articolo.commi}
                filter={filter}

                section={section}
                depth={depth}

                lawClient={lawClient}

                handlePreviousSection={props.handlePreviousSection}
                handleNextSection={props.handleNextSection}
                setDepth={props.setDepth}
                setSection={props.setSection}
                reloadRows={props.reloadRows}

                setDeleteId={props.setDeleteId}
                setDeleteEndpoint={props.setDeleteEndpoint}
              />
            }
            { (depth === depthLevels.paragraph && section === sectionViewTypes.attached || (articolo.allegato !== undefined && articolo.allegato !== null && articolo.allegato.descrizione !== null) ) &&
                <Attached
                  headId={articolo.id}
                  allegato={articolo.allegato !== null ? articolo.allegato : {descrizione: null}}
                  filter={filter}

                  section={props.section}
                  depth={props.depth}

                  lawClient={lawClient}

                  handlePreviousSection={props.handlePreviousSection}
                  handleNextSection={props.handleNextSection}
                  setDepth={props.setDepth}
                  setSection={props.setSection}
                  reloadRows={props.reloadRows}

                  setDeleteId={props.setDeleteId}
                  setDeleteEndpoint={props.setDeleteEndpoint}
                />
              }
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )

}

function ParagraphesTable(props) {

  props.commi.sort(compareFn)

  const { lawId, titleId, headId, articleId, section, depth, filter, lawClient } = props

  const [paragraphes, setParagraphes] = React.useState(props.commi)
  const [isAddingParagraph, setIsAddingParagraph] = React.useState(false)

  React.useEffect(() => {
    setParagraphes(props.commi)
  }, [props.commi])

  const handleCreateNewParagraph = () => {
    if( !isAbleToManageRow(userRole, lawClient) ) return
    let newParagraphes = [...paragraphes]
    newParagraphes.push(createBlankParagraph())
    setParagraphes(newParagraphes)
    setIsAddingParagraph(true)
  }

  const handleDiscardNewParagraph = () => {
    if( !isAbleToManageRow(userRole, lawClient) ) return
    let newParagraphes = [...paragraphes]
    newParagraphes.find((paragraph) => { return paragraph.id === 0 })
    newParagraphes.discardNewRow()
    setParagraphes(newParagraphes)
    setIsAddingParagraph(false)
  }

  const handleSaveNewParagraph = () => {
    if( !isAbleToManageRow(userRole, lawClient) ) return
    let newParagraph = paragraphes.find((el) => { return el.id === 0 } )
    delete newParagraph.id
    newParagraph.id_legge = lawId !== undefined ? lawId : null
    newParagraph.id_titolo = titleId !== undefined ? titleId : null
    newParagraph.id_capo = headId !== undefined ? headId : null
    newParagraph.id_articolo = articleId !== undefined ? articleId : null
    console.log('Saving new paragraph', newParagraph)
    setIsAddingParagraph(false)

    fetch(`${config.API_REPOSITORY}/paragraphes/addParagraph.php`, {
      method: 'POST',
      body: JSON.stringify(newParagraph),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Post result:', data);
      if ( !data ) alert('Errore durante la modifica. Aggiornare la pagina e riprovare')
      else props.reloadRows()
    })

  }

  const handleSaveChanges = (id, data, column) => {
    if( !isAbleToManageRow(userRole, lawClient) ) return
    console.log(`Saving changes (${id}):`, column, '=>', data)
    let newParagraphes = [...paragraphes]
    for(let i=0; i<newParagraphes.length; i++) { if( newParagraphes[i].id === id ) newParagraphes[i][column] = data}
    setParagraphes(newParagraphes)

    fetch(`${config.API_REPOSITORY}/paragraphes/modifyParagraph.php`, {
      method: 'POST',
      body: JSON.stringify({
        id: id,
        column: column,
        value: data
      }),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Post result:', data);
      if ( !data ) alert('Errore durante la modifica. Aggiornare la pagina e riprovare')
    })

    console.log('Paragraph saved')
  }

  const handleDeleteParagraph = (id) => {
    if( !isAbleToManageRow(userRole, lawClient) ) return
    console.log('Deleting paragraph', paragraphes.find((el) => { return el.id === id } ))

    props.setDeleteId(id)
    props.setDeleteEndpoint(`${config.API_REPOSITORY}/paragraphes/deleteParagraph.php`)
  }

  const handleSaveChangesNewParagraph = (data, column) => {
    if( !isAbleToManageRow(userRole, lawClient) ) return
    let newParagraphes = [...paragraphes]
    newParagraphes.find((el) => { return el.id === 0 })[column] = data
    setParagraphes(newParagraphes)
    console.log('New paragraph changed')
  }

  return(
    <Box sx={{ margin: 1 }}>
      <Typography variant="h6" gutterBottom component="div" sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          {
            isAddingParagraph === false
            ?
            <IconButton
              disabled={!isAbleToManageRow(userRole, lawClient)}
            >
              <AddCircleOutlineIcon
                onClick={handleCreateNewParagraph}
              />
            </IconButton>
            :
            <IconButton
              disabled={!isAbleToManageRow(userRole, lawClient)}
            >
              <RemoveCircleOutlineIcon
                onClick={handleDiscardNewParagraph}
              />
            </IconButton>
          }
          
          Commi
          { paragraphes.length === 0 &&
            <IconButton
              disabled={!isAbleToManageRow(userRole, lawClient)}
            >
              <UndoIcon
                onClick={props.handlePreviousSection}
              />
            </IconButton>
          }
          { paragraphes.length === 0 &&
            <IconButton
              disabled={!isAbleToManageRow(userRole, lawClient)}
            >
              <RedoIcon
                onClick={props.handleNextSection}
              />
            </IconButton>
          }
      </Typography>
      <Table size="small" aria-label="purchases">
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell align="center">Descrizione</TableCell>
            <TableCell align="center">Assessment</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paragraphes.map((comma) => {
            let el =
            <ParagraphRow
              key={`Paragraph-${comma.id}`} 
              comma={comma}
              isAddingParagraph={isAddingParagraph}

              section={section}
              depth={depth}

              lawClient={lawClient}

              handleSaveNewParagraph={handleSaveNewParagraph}
              handleSaveChanges={handleSaveChanges}
              handleSaveChangesNewParagraph={handleSaveChangesNewParagraph}
              handleDeleteParagraph={handleDeleteParagraph}

              handlePreviousSection={props.handlePreviousSection}
              handleNextSection={props.handleNextSection}
              setDepth={props.setDepth}
              setSection={props.setSection}
              reloadRows={props.reloadRows}

              setDeleteId={props.setDeleteId}
              setDeleteEndpoint={props.setDeleteEndpoint}
            />

            let f = false
            if ( comma.descrizione !== undefined && String(comma.descrizione).toLowerCase().includes(filter.toLowerCase()) ) {
              f = true
            }

            return(
                <>
                    {   filter !== '' 
                        ? f && el
                        : el
                    }
                </>
            )

          })}
        </TableBody>
      </Table>
    </Box>
  );

}

function ParagraphRow (props) {

  const { isAddingParagraph, section, depth, lawClient } = props
  const [comma, setComma] = React.useState(props.comma)

  React.useEffect(() => {
    setComma(props.comma)
  }, [props.comma])

  return (
    <TableRow>
      <TableCell>
        <EditableLabel
          style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem', fontWeight: 'bold', fontStyle: 'italic', whiteSpace: 'nowrap'}}
          initialValue={comma.nome}
          onBlur={value => { 
            if(isAddingParagraph === false) { props.handleSaveChanges(comma.id, value, 'nome') }
            else { props.handleSaveChangesNewParagraph(value, 'nome') }  
          }}
          editable={isAbleToManageRow(userRole, lawClient)}
        />
      </TableCell>
      <TableCell align="center">
        <EditableLabel
          style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem', textAlign: 'justify'}}
          initialValue={comma.descrizione}
          onBlur={value => { 
            if(isAddingParagraph === false) { props.handleSaveChanges(comma.id, value, 'descrizione') }
            else { props.handleSaveChangesNewParagraph(value, 'descrizione') }  
          }}
          editable={isAbleToManageRow(userRole, lawClient)}
        />
      </TableCell>
      <TableCell align="center">
        <Checkbox 
          checked={(comma.assessment === true || comma.assessment === 1) ? true : false} 
          onChange={event => { 
            if(isAddingParagraph === false) { props.handleSaveChanges(comma.id, event.target.checked, 'assessment') }
            else { props.handleSaveChangesNewParagraph(event.target.checked, 'assessment') }  
          }}
          disabled={!isAbleToManageRow(userRole, lawClient)}
        />
      </TableCell>
      <TableCell align="right">
        {
          comma.id !== 0
          ?
          (lawClient !== 0 || userRole === -1 || userRole === 1) &&
          <IconButton color="error" disabled={!isAbleToManageRow(userRole, lawClient)}>
            <DeleteIcon
              onClick={() => props.handleDeleteParagraph(comma.id)}
            />
          </IconButton>
          :
          <IconButton color="success" disabled={!isAbleToManageRow(userRole, lawClient)}>
            <SaveIcon
              onClick={props.handleSaveNewParagraph}
            />
          </IconButton>
        }
      </TableCell>
    </TableRow>
  )

}

function Attached(props) {

  const { lawId, titleId, headId, articleId, section, depth, filter, lawClient } = props

  console.log(props.allegato)

  const handleSaveNewAttached = (id, data) => {
    if( !isAbleToManageRow(userRole, lawClient) ) return

    let newAttached = { descrizione: data}
    newAttached.id_legge = lawId !== undefined ? lawId : null
    newAttached.id_titolo = titleId !== undefined ? titleId : null
    newAttached.id_capo = headId !== undefined ? headId : null
    newAttached.id_articolo = articleId !== undefined ? articleId : null
    console.log('Saving new attached', newAttached)

    fetch(`${config.API_REPOSITORY}/attacheds/addAttached.php`, {
      method: 'POST',
      body: JSON.stringify(newAttached),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Post result:', data);
      if ( !data ) alert('Errore durante la modifica. Aggiornare la pagina e riprovare')
      else props.reloadRows()
    })

  }

  const handleSaveChanges = (id, data) => {
    if( !isAbleToManageRow(userRole, lawClient) ) return
    fetch(`${config.API_REPOSITORY}/attacheds/modifyAttached.php`, {
      method: 'POST',
      body: JSON.stringify({
        id: id,
        value: data
      }),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Post result:', data);
      if ( !data ) alert('Errore durante la modifica. Aggiornare la pagina e riprovare')
      else props.reloadRows()
    })

    console.log('Attached saved')
  }

  return(
    <Box sx={{ margin: 1 }}>
      <Typography variant="h6" gutterBottom component="div" sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          
          Allegato
          { props.allegato.descrizione === null &&
            <IconButton
              disabled={!isAbleToManageRow(userRole, lawClient)}
            >
              <UndoIcon
                onClick={props.handlePreviousSection}
              />
            </IconButton>
          }
          { props.allegato.descrizione === null && lawId !== undefined &&
            <IconButton
              disabled={!isAbleToManageRow(userRole, lawClient)}
            >
              <RedoIcon
                onClick={props.handleNextSection}
              />
            </IconButton>
          }
      </Typography>
      <div style={{ padding: '20px 10px'}}>
        <EditableLabel
          style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem', textAlign: 'justify'}}
          initialValue={props.allegato.descrizione !== null ? props.allegato.descrizione : ''}
          onBlur={value => { 
            if(props.allegato.descrizione !== null && props.allegato.id !== undefined) { handleSaveChanges(props.allegato.id, value) }
            else if(value !== '') { handleSaveNewAttached(lawId, value) }  
          }}
          editable={isAbleToManageRow(userRole, lawClient)}
        />
      </div>
    </Box>
  );
}

function Sentence(props) {

  const { lawId, section, depth, filter, lawClient } = props

  console.log(props.sentenza)

  const handleSaveNewSentence = (id, data) => {
    if( !isAbleToManageRow(userRole, lawClient) ) return
    fetch(`${config.API_REPOSITORY}/sentences/addSentence.php`, {
      method: 'POST',
      body: JSON.stringify({
        id: id,
        value: data
      }),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Post result:', data);
      if ( !data ) alert('Errore durante la modifica. Aggiornare la pagina e riprovare')
      else props.reloadRows()
    })

  }

  const handleSaveChanges = (id, data) => {
    if( !isAbleToManageRow(userRole, lawClient) ) return
    fetch(`${config.API_REPOSITORY}/sentences/modifySentence.php`, {
      method: 'POST',
      body: JSON.stringify({
        id: id,
        value: data
      }),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Post result:', data);
      if ( !data ) alert('Errore durante la modifica. Aggiornare la pagina e riprovare')
      else props.reloadRows()
    })

    console.log('Sentence saved')
  }

  return(
    <Box sx={{ margin: 1 }}>
      <Typography variant="h6" gutterBottom component="div" sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          
          Sentenza
          { props.sentenza.descrizione === null &&
            <IconButton
              disabled={!isAbleToManageRow(userRole, lawClient)}
            >
              <UndoIcon
                onClick={props.handlePreviousSection}
              />
            </IconButton>
          }
      </Typography>
      <div style={{ padding: '20px 10px'}}>
        <EditableLabel
          style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem', textAlign: 'justify'}}
          initialValue={props.sentenza.descrizione !== null ? props.sentenza.descrizione : ''}
          onBlur={value => { 
            if(props.sentenza.descrizione !== null && props.sentenza.id !== undefined) { handleSaveChanges(props.sentenza.id, value) }
            else if(value !== '') { handleSaveNewSentence(lawId, value) }  
          }}
          editable={isAbleToManageRow(userRole, lawClient)}
        />
      </div>
    </Box>
  );
}

function compareFn(a, b) {

  a = a.nome
  b = b.nome

  if( typeof a === 'number' && typeof b === 'number') {
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    }
  }

  const suff = {
    bis: 1, 
    ter: 2,
    quater: 3,
    quinquies: 4,
    sexies: 5,
    septies: 6,
    octies: 7,
    novies: 8,
    decies: 9,
    undecies: 10,
    duodecies: 11,
    terdecies: 12,
    quaterdecies: 13,
    quinquiesdecies: 14,
    sexiesdecies: 15,
    septiesdecies: 16,
    duodevicies: 17
  }

  const romanNumber = [
    'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X', 
    'XI', 'XII', 'XIII', 'XIV', 'XV', 'XVI', 'XVII', 'XVIII', 'XIX', 'XX', 
    'XXI', 'XXII', 'XXIII', 'XXIV', 'XXV', 'XXVI', 'XXVII', 'XXVIII', 'XXIX', 'XXX', 
    'XXXI', 'XXXII', 'XXXIII', 'XXXIV', 'XXXV', 'XXXVI', 'XXXVII', 'XXXVIII', 'XXXIX', 'XL', 
    'XLI', 'XLII', 'XLIII', 'XLIV', 'XLV', 'XLVI', 'XLVII', 'XLVIII', 'XLIX', 'L'
  ]

  let numA = null
  let numB = null
  let secA = 0
  let secB = 0

  a = a.toString()
  b = b.toString()

  if ( a.includes('Allegato') && ! b.includes('Allegato') ) return 1;
  if ( ! a.includes('Allegato') && b.includes('Allegato') ) return -1;

  if (
    a.includes('Titolo') || b.includes('Titolo')
  ) 
  {
    for (let n of romanNumber) {
      if(isExactMatch(a, n)) numA = romanToInt(n)
      if(isExactMatch(b, n)) numB = romanToInt(n)
    }
  } else if(
    a.includes('Capo') || b.includes('Capo')
  ) 
  {
    if(a.includes('Sezione') || b.includes('Sezione')) {
      let splittedA = a.split('-')
      let splittedB = b.split('-')
      let headA = splittedA[0]
      let headB = splittedB[0]
      let sectionA = splittedA.length > 1 ? splittedA[1] : ''
      let sectionB = splittedB.length > 1 ? splittedB[1] : ''
      for (let n of romanNumber) {
        if(isExactMatch(headA, n)) numA = romanToInt(n)
        if(isExactMatch(headB, n)) numB = romanToInt(n)
        if(isExactMatch(sectionA, n)) secA = romanToInt(n)
        if(isExactMatch(sectionB, n)) secB = romanToInt(n)
      }
    } else {
      for (let n of romanNumber) {
        if(isExactMatch(a, n)) numA = romanToInt(n)
        if(isExactMatch(b, n)) numB = romanToInt(n)
      }
    }
  } else {
    let searchNumberA = a.match(/\d+/g)
    if (searchNumberA === null) return 1
    numA = parseInt(searchNumberA.length > 0 ? searchNumberA[0] : '0')
    let searchNumberB = b.match(/\d+/g)
    if (searchNumberB === null) return -1
    numB = parseInt(searchNumberB.length > 0 ? searchNumberB[0] : '0')
  }

  const nameA = a.toLowerCase();
  const nameB = b.toLowerCase();

  let suffA = 0
  let suffB = 0
  for (const [key, value] of Object.entries(suff)) {
    if(nameA.includes(key)) suffA = value
    if(nameB.includes(key)) suffB = value
  }

  if (numA < numB) {
    return -1;
  } else if (numA > numB) {
    return 1;
  }

  if (suffA < suffB) {
    return -1;
  }
  if (suffA > suffB) {
    return 1;
  }

  if (secA < secB) {
    return -1;
  }
  if (secA > secB) {
    return 1;
  }

  return 0;

}

function romanToInt(s) {

  const romanHash = {
    I: 1,
    V: 5,
    X: 10,
    L: 50,
    C: 100,
    D: 500,
    M: 1000
  };

  let accumulator = 0;
  for (let i = 0; i < s.length; i++) {
    if (s[i] === "I" && s[i + 1] === "V") {
      accumulator += 4;
      i++;
    } else if (s[i] === "I" && s[i + 1] === "X") {
      accumulator += 9;
      i++;
    } else if (s[i] === "X" && s[i + 1] === "L") {
      accumulator += 40;
      i++;
    } else if (s[i] === "X" && s[i + 1] === "C") {
      accumulator += 90;
      i++;
    } else if (s[i] === "C" && s[i + 1] === "D") {
      accumulator += 400;
      i++;
    } else if (s[i] === "C" && s[i + 1] === "M") {
      accumulator += 900;
      i++;
    } else {
      accumulator += romanHash[s[i]];
    }
  }

  return accumulator;
}

function escapeRegExpMatch (s) {
  return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
};
function isExactMatch (str, match) {
  return new RegExp(`\\b${escapeRegExpMatch(match)}\\b`).test(str)
}

function highlightText(text, filter) {
  let indexes = getIndicesOf(text, filter)
  console.log(indexes)
  let formattedText = ''
  for (let index of indexes) {
    formattedText = text.substring(0, index) + "<mark>" + text.substring(index, index + filter.length) + "</mark>" + text.substring(index + filter.length);
  }

  return formattedText
}

function getIndicesOf(str, searchStr) {
  var searchStrLen = searchStr.length;
  if (searchStrLen == 0) {
      return [];
  }
  var startIndex = 0, index, indices = [];
  str = str.toLowerCase();
  searchStr = searchStr.toLowerCase();
  while ((index = str.indexOf(searchStr, startIndex)) > -1) {
      indices.push(index);
      startIndex = index + searchStrLen;
  }
  return indices;
}

Array.prototype.discardNewRow = function () {
  if (this.at( this.length -1 ).id === 0) this.pop()
}