import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
//import Select from '@mui/material/Select';
import Select from 'react-select'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const ENTER_KEY_CODE = 13;
const DEFAULT_LABEL_PLACEHOLDER = <p style={{ color: '#808080', margin: 0 }}><em>Clicca per inserire</em></p>

export default function EditableSelect ({ 
    onFocus = () => {}, 
    onBlur = () => {},
    ...props
    }) {

    const [isEditing, setEditing] = React.useState(false)
    const [value, setValue] = React.useState(props.initialValue);

    let labelStyle = props.style
    if(labelStyle === undefined) labelStyle = {}
    if(labelStyle.cursor === undefined && props.editable) labelStyle.cursor = 'pointer'

    const isTextValueValid = () => typeof value !== "undefined";

    /*const handleChange = (event) => {
        setValue(event.target.value);
    };*/

    const handleChange = (value) => {
        value !== undefined && value !== null && setValue(value.id);
    };

    const handleFocus = () => {
        const fn = isEditing ? onBlur : onFocus;
        fn(value);
        handleEditState();
    };

    const handleKeyDown = e => {
        if (e.keyCode === ENTER_KEY_CODE) {
            handleEnterKey();
        }
    };

    const handleEditState = () => {
        if(!isTextValueValid()) return;
        if(props.editable) setEditing(prev => !prev);
    };

    const handleEnterKey = () => {
        handleFocus();
    };

    if(isEditing && props.editable) {

        let inVal = props.options.find((el) => el.id === value)

        return (
            /*<FormControl variant="standard">
                <Select
                    value={value}
                    onChange={handleChange}
                    onBlur={handleFocus}
                    onKeyDown={handleKeyDown}
                    autoFocus
                    style={props.style}
                >
                    {props.options.map((op) => {
                        return <MenuItem value={op.id}>{op.nome}</MenuItem>
                    })}
                </Select>
            </FormControl>*/
            /*<Autocomplete
                value={value}
                onChange={handleChange}
                onBlur={handleFocus}
                onKeyDown={handleKeyDown}
                autoFocus
                style={props.style}
                options={props.options}
                getOptionLabel={(option) => { console.log(option); return option.nome} }
                renderOption={(option) => <span>{option.name}</span>}
                //sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Seleziona..." />}
            />*/
            <Select 
                options={props.options}
                getOptionValue={ (option) => String(option.id) }
                getOptionLabel={ (option) => String(option.nome) }

                isClearable

                defaultValue={String(value)}
                defaultInputValue={inVal !== undefined ? inVal.nome : ''}
                onChange={handleChange}
                onBlur={handleFocus}
                onKeyDown={handleKeyDown}
                autoFocus
                menuPortalTarget={document.body} 
                style={props.style}
            />
        )
    }

    let selectedOption = null
    for(let i=0; i<props.options.length; i++) {
        if(props.options[i].id === value) {
            selectedOption = props.options[i]
            break
        }
    }

    const labelText = isTextValueValid() && ( value !== 0 || (value === 0 && selectedOption !== undefined && selectedOption !== null && selectedOption.nome === 'Globale')) && selectedOption !== null
        ? selectedOption.nome
        : props.labelPlaceHolder || DEFAULT_LABEL_PLACEHOLDER;

    return <InputLabel onClick={handleFocus} style={labelStyle}>{labelText}</InputLabel>;
}