import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const ENTER_KEY_CODE = 13;
const DEFAULT_LABEL_PLACEHOLDER = <p style={{ color: '#808080', margin: 0 }}><em>Clicca per inserire</em></p>

export default function EditableProgressbar ({ 
        onFocus = () => {}, 
        onBlur = () => {},
        ...props
    }) {
    const [isEditing, setEditing] = useState(false);
    const [value, setValue] = useState(props.initialValue);
    const inputRef = useRef(null);
    
    let labelStyle = props.style
    if(labelStyle === undefined) labelStyle = {}
    if(labelStyle.cursor === undefined && props.editable) labelStyle.cursor = 'pointer'

    const isTextValueValid = () => typeof value !== "undefined"

    const handleFocus = () => {
        const fn = isEditing ? onBlur : onFocus;
        fn(value);
        handleEditState();
    };

    const handleChange = e => setValue(inputRef.current.value);

    const handleKeyDown = e => {
        if (e.keyCode === ENTER_KEY_CODE) {
            handleEnterKey();
        }
    };

    const handleEditState = () => {
        if(!isTextValueValid()) return;
        if(props.editable) setEditing(prev => !prev);
    };

    const handleEnterKey = () => {
        handleFocus();
    };
        
    if (isEditing && props.editable) {
        return (
            <Input
                inputProps={{
                    ref: inputRef,
                    value,
                    min: 0,
                    max: 100
                }}
                type='number'
                onChange={handleChange}
                onBlur={handleFocus}
                onKeyDown={handleKeyDown}
                autoFocus
                style={props.style}
            />
        );
    }

    const progressValue = isTextValueValid() && typeof value === "number" 
        ? value
        : parseInt(value)

    const barColor = progressValue <=35 
                    ? 'error'
                    : progressValue <= 65
                        ? 'warning'
                        : 'success'

    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }} onClick={handleFocus} style={labelStyle}>
            <CircularProgress variant="determinate" color={barColor} value={progressValue} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">
                    {`${Math.round(progressValue)}%`}
                </Typography>
            </Box>
        </Box>
    )
};

EditableProgressbar.propTypes = {
  initialValue: PropTypes.string.isRequired,
  emptyEdit: PropTypes.bool,
  number: PropTypes.bool,
  perc: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
};