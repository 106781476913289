import logo from './logo.svg';
import './App.css';

import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { useState } from "react"

import Login from './components/Login'

import Topbar from './components/Topbar'
import Dashboard from './components/Dashboard';
import LegalRepository from './components/LegalRepository'
import AssesmentManagment from './components/AssessmentManagment'
import ChoiceTables from './components/Choices'
import Users from './components/Users'
import Footer from './components/Footer'

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import { isLoggedIn, logout } from './helpers/login.js'

function App() {

  const [legalId, setLegalId] = useState(null)
  const [clientId, setClientId] = useState(null)
  const location = useLocation();

  if(location.pathname.includes('logout')) {
    console.log('Logout')
    logout()
  }

  console.log(location.pathname)
  if (!isLoggedIn() && location.pathname !== '/site' && location.pathname !== '/site/' && location.pathname !== '/site/login' && location.pathname !== '/site/login/' && location.pathname !== '/site/logout' && location.pathname !== '/site/logout/') {
    console.log('Going to login...')
    window.location.href = '/site'
  }

  return (
    <Routes>
      <Route
        path="/site" 
        element={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100vh',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            className="login-container"
          > 
            <Paper sx={{ margin: '1em', width: 'fit-content', maxWidth: '350px', maxHeight: '400px' }} elevation={2}>
              <Login 
                
              />
            </Paper>
          </Box>
        }
      />
      <Route
        path="/site/login" 
        element={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100vh',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            className="login-container"
          > 
            <Paper sx={{ margin: '1em', width: 'fit-content', maxWidth: '350px', maxHeight: '400px' }} elevation={2}>
              <Login 
                
              />
            </Paper>
          </Box>
        }
      />
      <Route
        path="/site/logout" 
        element={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100vh',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            className="login-container"
          > 
            <Paper sx={{ margin: '1em', width: 'fit-content', maxWidth: '350px', maxHeight: '400px' }} elevation={2}>
              <Login 
                
              />
            </Paper>
          </Box>
        }
      />
      <Route 
        path="/site/dashboard" 
        element={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100vh',
            }}
          > 
            <Topbar />
              <Paper sx={{ margin: '1em' }} elevation={0}>
                <Dashboard 
                  
                />
              </Paper>
            <Footer />
          </Box>
        }/>
      <Route 
        path="/site/repository" 
        element={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100vh',
            }}
          > 
            <Topbar />
            <Paper sx={{ margin: '1em' }} elevation={0}>
              <LegalRepository 
                setLegalId={setLegalId}
                setClientId={setClientId}
              />
            </Paper>
            <Footer />
          </Box>
        }/>
      <Route 
        path="/site/assessment-managment" 
        element={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100vh',
            }}
          > 
            <Topbar />
            <Paper sx={{ margin: '1em' }} elevation={0}>
              <AssesmentManagment 
                legalId={legalId}
                clientId={clientId}
              />
            </Paper>
            <Footer />
          </Box>
        }/>
      <Route 
        path="/site/choices" 
        element={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100vh',
            }}
          > 
            <Topbar />
            <Paper sx={{ margin: '1em' }} elevation={0}>
              <ChoiceTables 

              />
            </Paper>
            <Footer />
          </Box>
        }/> 
      <Route 
        path="/site/users" 
        element={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100vh',
            }}
          >
            <Topbar />
            <Paper sx={{ margin: '1em' }} elevation={0}>
              <Users 
                setLegalId={setLegalId}
                setClientId={setClientId}
              />
            </Paper>
            <Footer />
          </Box>
        }/>   
    </Routes>
  );
}

export default App;
