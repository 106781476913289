import React, { useState, useRef } from 'react';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';

const ENTER_KEY_CODE = 13;
const DEFAULT_LABEL_PLACEHOLDER = <p style={{ color: '#808080', margin: 0 }}><em>Clicca per inserire</em></p>

export default function EditableDate ({ 
        onFocus = () => {}, 
        onBlur = () => {},
        ...props
    }) {

    const [isEditing, setEditing] = useState(false);
    const [value, setValue] = useState(props.initialValue);
    const inputRef = useRef(null);
    
    let labelStyle = props.style
    if(labelStyle === undefined) labelStyle = {}
    if(labelStyle.cursor === undefined && props.editable) labelStyle.cursor = 'pointer'

    const isTextValueValid = () => typeof value !== "undefined"

    const handleFocus = () => {
        const fn = isEditing ? onBlur : onFocus;
        fn(value);
        handleEditState();
    };

    const handleChange = e => { setValue(inputRef.current.value !== "" && inputRef.current.value !== "0000-00-00" ? inputRef.current.value : null) }

    const handleKeyDown = e => {
        if (e.keyCode === ENTER_KEY_CODE) {
            handleEnterKey();
        }
    };

    const handleEditState = () => {
        if(!isTextValueValid()) return;
        if(props.editable) setEditing(prev => !prev);
    };

    const handleEnterKey = () => {
        handleFocus();
    };
        
    if (isEditing && props.editable) {
        return (
            <TextField
                label="Entrata..."
                type="date"
                inputProps={{
                    ref: inputRef,
                    value
                }}
                onChange={handleChange}
                onBlur={handleFocus}
                onKeyDown={handleKeyDown}
                handleKeyPress={(event) => {
                    if(event.key === 'Backspace'){
                        console.log('Backspace press here! ')
                    }
                }}
                autoFocus
                style={props.style}
            />
        );
    }

    const labelText = isTextValueValid() && value != null
        ? formatDateLabel(value)
        : props.labelPlaceHolder || DEFAULT_LABEL_PLACEHOLDER;

    return <InputLabel onClick={handleFocus} style={labelStyle}>{labelText}</InputLabel>;

};

const formatDateLabel = (date) => {
    let dParts = date.split('-')
    return dParts[2] + '/' + dParts[1] + '/' + dParts[0]
}