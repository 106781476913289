import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import TextareaAutosize from "@mui/material/TextareaAutosize";

const ENTER_KEY_CODE = 13;
const DEFAULT_LABEL_PLACEHOLDER = <p style={{ color: '#808080', margin: 0 }}><em>Clicca per inserire</em></p>

const EditableLabel = ({ 
        onFocus = () => {}, 
        onBlur = () => {},
        ...props
    }) => {
    const [isEditing, setEditing] = useState(false);
    const [value, setValue] = useState(typeof props.initialValue !== 'object' ? props.initialValue : '');
    const inputRef = useRef(null);
    
    let labelStyle = props.style
    if(labelStyle === undefined) labelStyle = {}
    if(labelStyle.cursor === undefined && props.editable) labelStyle.cursor = 'pointer'

    const isTextValueValid = () => typeof value !== "undefined"

    const handleFocus = () => {
        const fn = isEditing ? onBlur : onFocus;
        fn(value);
        handleEditState();
    };

    const handleChange = e => setValue(inputRef.current.value);

    const handleKeyDown = e => {
        if (e.keyCode === ENTER_KEY_CODE) {
            handleEnterKey();
        }
    };

    const handleEditState = () => {
        if(!isTextValueValid()) return;
        if(props.editable) setEditing(prev => !prev);
    };

    const handleEnterKey = () => {
        handleFocus();
    };
        
    if (isEditing && props.editable) {
        return(
            props.number !== undefined && props.number
            ?
            <Input
                inputProps={{
                    ref: inputRef,
                    value,
                    min: props.min,
                    max: props.max
                }}
                type={'number'}
                onChange={handleChange}
                onBlur={handleFocus}
                onKeyDown={handleKeyDown}
                autoFocus
                style={props.style}
            />
            :
            <TextareaAutosize
                ref={inputRef}
                onChange={handleChange}
                onBlur={handleFocus}
                onKeyDown={handleKeyDown}
                autoFocus
                style={{...props.style, width: '100%'}}
                defaultValue={typeof value !== 'object' ? value : ''}
            />
        )
    }

    const labelText = isTextValueValid() && typeof props.initialValue !== 'object'
        ? ( (typeof value === "string" && value.length > 0 && value !== '') || typeof value === "number")
            ? value
            : props.labelPlaceHolder || DEFAULT_LABEL_PLACEHOLDER
        : ( props.initialValue !== null )
            ? props.initialValue
            : props.labelPlaceHolder || DEFAULT_LABEL_PLACEHOLDER

    return <InputLabel onClick={handleFocus} style={labelStyle}>{labelText}{props.perc ? '%' : ''}</InputLabel>;
};

EditableLabel.propTypes = {
  // initialValue: PropTypes.string.isRequired,
  emptyEdit: PropTypes.bool,
  number: PropTypes.bool,
  perc: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
};

export default EditableLabel;