export function isLoggedIn() {
    let tmp = window.sessionStorage.getItem('loggedIn')
    return tmp !== undefined && tmp === 'true'
}

export function setLoggedUser(user) {
    window.sessionStorage.setItem('loggedIn', true)
    window.sessionStorage.setItem('loggedUser', user)
}

export function getLoggedUser() {
    return JSON.parse(window.sessionStorage.getItem('loggedUser'))
}

export function getRole() {
    return getLoggedUser()['ruolo']
}

export function getClient() {
    return getLoggedUser()['cliente']
}

export function logout() {
    window.sessionStorage.setItem('loggedIn', false)
    window.sessionStorage.removeItem('loggedUser')
}