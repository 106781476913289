import * as React from 'react';
import axios from "axios";

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import InputLabel from '@mui/material/InputLabel';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SettingsIcon from '@mui/icons-material/Settings';

import { Link } from 'react-router-dom';

import EditableLabel from '../UI/EditableLabel';
import EditableSelect from '../UI/EditableSelect';
import SearchBar from '../UI/SearchBar';
import PaginatedTable from '../UI/PaginatedTable';

import { config } from '../config';
import { getRole, getClient } from '../helpers/login';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

function createBlankUser() {
    return {
      id: 0,
      email: '',
      password: '',
      ruolo: 0
    }
}

function isAbleToManageRow(role) {
    switch(role) {
      case -1: return true
      case 1: return true
      default: return true
    }
}

let userRole = null
let userClient = null

export default function ChoiceTables(props) {

    const [users, setUsers] = React.useState([])
    const [clients, setClients] = React.useState([])
    const [clientLaws, setClientLaws] = React.useState([])
    const [roles, setRoles] = React.useState([])

    userRole = getRole()
    userClient = getClient()

    function getUsers() {
        axios.post(`${config.API_USERS}/users/getUsers.php`)
            .then((response) => {
                console.log (response.data)
                setUsers( response.data );
            });
    }

    function getClients() {
        axios.post(`${config.API_USERS}/clients/getClients.php`)
            .then((response) => {
                console.log (response.data)
                setClients( response.data );
            });
    }

    function getRoles() {
        axios.post(`${config.API_USERS}/getRoles.php`)
            .then((response) => {
                console.log (response.data)
                setRoles( response.data );
            });
    }

    React.useEffect(() => {
        getUsers()
        getClients()
        getRoles()
    }, [])

    return(
        <Grid container spacing={2} sx={{ border: 0}}>
            <Grid item md={6} sm={12}>
                <Grid container spacing={2} columns={1}>
                    <Grid item md={6} sm={12}>
                        <PaginatedTable
                            title="Clienti/aziende"
                            rows={clients}
                            perPage={5}
                            rowsPerPageOptions={[5, 25, 50, { label: 'All', value: -1 }]}

                            addEndpoint={`${config.API_USERS}/clients/addClient.php`}
                            modifyEndpoint={`${config.API_USERS}/clients/modifyClient.php`}
                            deleteEndpoint={`${config.API_USERS}/clients/deleteClient.php`}

                            reloadRows={getClients}

                            handleRowLink={(id) => {
                                fetch(`${config.API_USERS}/getClientLaws.php`, {
                                    method: 'POST',
                                    body: JSON.stringify({
                                        id: id
                                    }),
                                })
                                .then(response => response.json())
                                .then(data => {
                                    console.log('Post result:', data);
                                    setClientLaws( data )
                                })
                            }}

                            editable={isAbleToManageRow(userRole)}
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <UsersTable 
                            rows={users}
                            reloadRows={getUsers}
                            perPage={5}
                            rowsPerPageOptions={[5, 25, 50, { label: 'All', value: -1 }]}

                            roles={roles}
                            clients={clients}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item md={6} sm={12}>
                <LawsTable
                    rows={clientLaws}
                    perPage={10}
                    rowsPerPageOptions={[10, 20, 30, { label: 'All', value: -1 }]}

                    setLegalId={props.setLegalId}
                    setClientId={props.setClientId}
                />
            </Grid>

        </Grid>
    )

}

function UsersTable(props) {

    const filterColumns = [ 'email' ]

    const [rows, setRows] = React.useState(props.rows)
    const [isAddingRow, setIsAddingRow] = React.useState(false)

    React.useEffect(() => {
        setRows(props.rows)
    }, [props.rows])

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.perPage !== undefined ? props.perPage : props.rowsPerPageOptions[0]);

    const [filter, setFilter] = React.useState('')

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    const handleCreateNewRow = () => {
        let newRows = [...rows]
        newRows.push(createBlankUser())
        setRows(newRows)
        setIsAddingRow(true)
        let nPages = rows.length%rowsPerPage === 0 ? ( rows.length / rowsPerPage ) + 1 : Math.ceil(rows.length / rowsPerPage)
        setPage(nPages-1)
    }
    
    const handleDiscardNewRow = () => {
        let newRows = [...rows]
        newRows.discardNewRow()
        setRows(newRows)
        setIsAddingRow(false)
    }
    
    const handleSaveNewRow = () => {
        let newRow = rows.find((el) => { return el.id === 0 } )
        delete newRow.id
        console.log('Saving new user', newRow)
        setIsAddingRow(false)
    
        fetch(`${config.API_USERS}/users/addUser.php`, {
            method: 'POST',
            body: JSON.stringify(newRow),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Post result:', data);
            if ( !data ) alert('Errore durante la modifica. Aggiornare la pagina e riprovare')
            else props.reloadRows()
        })
    
    }
    
      const handleSaveChanges = (id, data, column) => {
        console.log(`Saving changes (${id}):`, column, '=>', data)
        let newRows = [...rows]
        for(let i=0; i<newRows.length; i++) { if( newRows[i].id === id ) newRows[i][column] = data }
        setRows(newRows)
    
        fetch(`${config.API_USERS}/users/modifyUser.php`, {
          method: 'POST',
          body: JSON.stringify({
            id: id,
            column: column,
            value: data
          }),
        })
        .then(response => response.json())
        .then(data => {
          console.log('Post result:', data);
          if ( !data ) alert('Errore durante la modifica. Aggiornare la pagina e riprovare')
        })
    
        console.log('Row saved')
      }
    
      const handleDeleteRow = (id) => {
        console.log('Deleting row', rows.find((el) => { return el.id === id } ))
    
        fetch(`${config.API_USERS}/users/deleteUser.php`, {
            method: 'POST',
            body: JSON.stringify({
              id: id
            }),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Post result:', data);
            if ( !data ) alert('Errore durante la modifica. Aggiornare la pagina e riprovare')
            else props.reloadRows()
        })
      }
    
      const handleSaveChangesNewRow = (data, column) => {
        let newRows = [...rows]
        newRows.find((el) => { return el.id === 0 })[column] = data
        setRows(newRows)
        console.log('New row changed')
      }

    let nFilteredRow = 0
    return (
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell align='center'>Email</StyledTableCell>
                        <StyledTableCell align='center'>Password</StyledTableCell>
                        <StyledTableCell align='center'>Ruolo</StyledTableCell>
                        <StyledTableCell align='center'>Cliente/azienda</StyledTableCell>
                        <StyledTableCell align='center'>
                            {
                                isAddingRow === false
                                ?
                                <AddCircleOutlineIcon
                                    sx={{ cursor: 'pointer' }}
                                    onClick={handleCreateNewRow}
                                />
                                :
                                <RemoveCircleOutlineIcon
                                    sx={{ cursor: 'pointer' }}
                                    onClick={handleDiscardNewRow}
                                />
                            }
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell align="center" colSpan={5} sx={{ padding: '0!important' }}>
                            <Paper elevation={2}>
                                <SearchBar
                                    value={filter}
                                    onChange={(newValue) => setFilter(newValue) }
                                    onCancelSearch={() => setFilter('') }
                                />
                            </Paper>
                        </TableCell>
                    </TableRow>
                    {(
                        rowsPerPage > 0
                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : rows
                        ).map((row) => {
                            
                            let el =
                            <StyledTableRow key={`User-${row.id}`}>
                                <StyledTableCell align="center">
                                    <EditableLabel
                                        style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem', whiteSpace: 'nowrap'}}
                                        initialValue={row.email}
                                        onBlur={value => { 
                                            if(isAddingRow === false || row.id !== 0) { handleSaveChanges(row.id, value, 'email') }
                                            else { handleSaveChangesNewRow(value, 'email') }  
                                        }}
                                        editable={isAbleToManageRow(userRole)}
                                    />
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <EditableLabel
                                        style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem', whiteSpace: 'nowrap'}}
                                        initialValue={row.password}
                                        onBlur={value => { 
                                            if(isAddingRow === false || row.id !== 0) { handleSaveChanges(row.id, value, 'password') }
                                            else { handleSaveChangesNewRow(value, 'password') }  
                                        }}
                                        editable={isAbleToManageRow(userRole)}
                                    />
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <EditableSelect
                                        style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem'}}
                                        initialValue={row.ruolo}
                                        onBlur={value => { 
                                            if(isAddingRow === false || row.id !== 0) { handleSaveChanges(row.id, value, 'ruolo') }
                                            else { handleSaveChangesNewRow(value, 'ruolo') }  
                                        }}
                                        options={props.roles}
                                        editable={isAbleToManageRow(userRole)}
                                    />
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {   row.ruolo !== -1 && row.ruolo !== 1
                                        ?
                                        <EditableSelect
                                            style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem'}}
                                            initialValue={row.cliente}
                                            onBlur={value => { 
                                                if(isAddingRow === false || row.id !== 0) { handleSaveChanges(row.id, value, 'cliente') }
                                                else { handleSaveChangesNewRow(value, 'cliente') }  
                                            }}
                                            options={props.clients}
                                            editable={isAbleToManageRow(userRole)}
                                        />
                                        :
                                        <InputLabel style={{ color: 'rgba(0, 0, 0, 0.50)', fontSize: '0.875rem', fontStyle: 'italic'}}>Tutte</InputLabel>
                                    }
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {
                                        row.id !== 0
                                        ?
                                        <IconButton>
                                            <DeleteIcon
                                                sx={{ cursor: 'pointer', color: 'red' }}
                                                onClick={() => handleDeleteRow(row.id)}
                                            />
                                        </IconButton>
                                        :
                                        <IconButton>
                                            <SaveIcon
                                                sx={{ cursor: 'pointer', color: 'green' }}
                                                onClick={handleSaveNewRow}
                                            />
                                        </IconButton>
                                    }
                                </StyledTableCell>
                            </StyledTableRow>

                            let f = false
                            filterColumns.forEach(c => {
                                if( row[c].toLowerCase().includes(filter.toLowerCase()) ) f = true
                            })

                            if(f) nFilteredRow++

                            return(
                                <>
                                    {   filter !== '' 
                                        ? f && el
                                        : el
                                    }
                                </>
                            )
                        })
                    }

                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={props.rowsPerPageOptions}
                            colSpan={6}
                            count={filter !== '' 
                                    ?   nFilteredRow
                                    :   rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}

function LawsTable(props) {

    const filterColumns = [ 'identificativo', 'ambito' ]

    const [rows, setRows] = React.useState(props.rows)

    React.useEffect(() => {
        setRows(props.rows)
    }, [props.rows])

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.perPage !== undefined ? props.perPage : props.rowsPerPageOptions[0]);

    const [filter, setFilter] = React.useState('')

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
      
    const handleChangeApplicability = (id_cliente, id_legge, value) => {
        let newRows = [...rows]
        for(let i=0; i<newRows.length; i++) { if( newRows[i].id_cliente === id_cliente && newRows[i].id_legge === id_legge ) newRows[i]['applicabilita'] = value}
        setRows(newRows)

        fetch(`${config.API_USERS}/modifyApplicability.php`, {
        method: 'POST',
        body: JSON.stringify({
            id_cliente: id_cliente,
            id_legge: id_legge,
            value: value
        }),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Post result:', data);
            if ( !data ) alert('Errore durante la modifica. Aggiornare la pagina e riprovare')
        })
    }

    let nFilteredRow = 0
    return (
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        {/*<StyledTableCell></StyledTableCell>*/}
                        <StyledTableCell align='center'>Identificativo</StyledTableCell>
                        <StyledTableCell align='center'>Ambito</StyledTableCell>
                        <StyledTableCell align='center'>Applicabilità</StyledTableCell>
                        <StyledTableCell align='center'>Assessment</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell align="center" colSpan={5} sx={{ padding: '0!important' }}>
                            <Paper elevation={2}>
                                <SearchBar
                                    value={filter}
                                    onChange={(newValue) => setFilter(newValue) }
                                    onCancelSearch={() => setFilter('') }
                                />
                            </Paper>
                        </TableCell>
                    </TableRow>
                    {(
                        rowsPerPage > 0
                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : rows
                        ).map((row) => {
                            
                            let el =
                            <LawRow 
                                key={row.id}
                                row={row}

                                setLegalId={props.setLegalId}
                                setClientId={props.setClientId}
                                handleChangeApplicability={handleChangeApplicability}
                            />

                            let f = false
                            filterColumns.forEach(c => {
                                if( row[c].toLowerCase().includes(filter.toLowerCase()) ) f = true
                            })

                            if(f) nFilteredRow++

                            return(
                                <>
                                    {   filter !== '' 
                                        ? f && el
                                        : el
                                    }
                                </>
                            )
                        })
                    }

                    {filter === '' && emptyRows > 0 && (
                        <TableRow style={{ height: 46.6 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    )}

                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={props.rowsPerPageOptions}
                            colSpan={6}
                            count={filter !== '' 
                                    ?   nFilteredRow
                                    :   rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}

function LawRow(props) {

    // const [open, setOpen] = React.useState(false);

    const { row } = props
    // const [paragraphesLaw, setParagraphesLaw] = React.useState([])

    /*React.useEffect(() => {
        fetch(`${config.API_USERS}/getParagraphesLaw.php`, {
            method: 'POST',
            body: JSON.stringify({
                lawId: row.id_legge,
                clientId: row.id_cliente
        }),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Post result:', data);
            if ( !data ) alert('Errore durante la modifica. Aggiornare la pagina e riprovare')
            else setParagraphesLaw(data)
        })
    }, [])*/
    
    return(
        <>
            <StyledTableRow key={row.id}>
                {/*<StyledTableCell>
                    {   (row.applicabilita === true || row.applicabilita === 1) &&
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    }
                </StyledTableCell>
                */}
                <StyledTableCell align="center">{row.identificativo}</StyledTableCell>
                <StyledTableCell align="center">{row.ambito}</StyledTableCell>
                <StyledTableCell align="center">
                    <Checkbox 
                        checked={(row.applicabilita === true || row.applicabilita === 1) ? true : false} 
                        onChange={ event => props.handleChangeApplicability( row.id_cliente, row.id_legge, event.target.checked ) }
                    />
                </StyledTableCell>
                <StyledTableCell align="center">
                    { (row.applicabilita === true || row.applicabilita === 1) &&
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={() => {
                                props.setLegalId(row.id_legge)
                                props.setClientId(row.id_cliente)
                            }}
                            sx={{ my: 2, color: 'white', display: 'block', textDecoration: 'none' }}
                            as={Link} 
                            to={`/site/assessment-managment`}
                        >
                            Assessment
                        </Button>
                    }
                </StyledTableCell>
            </StyledTableRow>
            {/*<TableRow>
                <StyledTableCell style={{ paddingBottom: 0, paddingTop: open ? '15px' : 0 }} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <ParagraphesTable
                            rows={paragraphesLaw !== undefined ? paragraphesLaw : []}

                            lawId={row.id_legge}
                            clientId={row.id_cliente}
                            applicabilita={row.applicabilita}

                            perPage={10}
                            rowsPerPageOptions={[10, 20, 30, { label: 'All', value: -1 }]}
                        />
                    </Collapse>
                </StyledTableCell>
            </TableRow>*/}
        </>
    )
}

function ParagraphesTable(props) {

    const [paragraphesData, setParagraphesData] = React.useState(props.rows)
    const [rows, setRows] = React.useState(props.rows.paragrafi !== undefined ? props.rows.paragrafi : [])

    React.useEffect(() => {
        setRows(props.rows.paragrafi !== undefined ? props.rows.paragrafi : [])
    }, [props.rows])

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.perPage !== undefined ? props.perPage : props.rowsPerPageOptions[0]);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
      
    const handleCreateAssessment = (id, info) => {

        fetch(`${config.API_USERS}/createAssessment.php`, {
        method: 'POST',
        body: JSON.stringify({
            info: info
        }),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Post result:', data);
            if ( !data ) alert('Errore durante la modifica. Aggiornare la pagina e riprovare')
            else {
                let newRows = [...rows]
                for(let i=0; i<newRows.length; i++) { if( newRows[i].id === id ) newRows[i]['assessment'] = true}
                setRows(newRows)
            }
        })
    }

    return (
        <TableContainer>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <TableCell align='center'>Titolo</TableCell>
                        <TableCell align='center'>Capo</TableCell>
                        <TableCell align='center'>Articolo</TableCell>
                        <TableCell align='center'>Comma</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(
                        rowsPerPage > 0
                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : rows
                        ).map((row) => {
                            
                            let info = {
                                id_cliente: props.clientId,
                                id_legge: props.lawId,
                                id_titolo: null,
                                id_capo: null,
                                id_articolo: null,
                                id_comma: row.id
                            }

                            let paragraph = row.nome
                            
                            let article = '-'
                            if(row.articolo !== undefined) {
                                article = row.articolo.nome
                                info.id_articolo = row.articolo.id
                            }
                            
                            let head = '-'
                            if(row.capo !== undefined) {
                                head = row.capo.nome
                                info.id_capo = row.capo.id
                            }
                            if(row.articolo !== undefined && row.articolo.capo !== undefined) {
                                head = row.articolo.capo.nome
                                info.id_capo = row.articolo.capo.id
                            }

                            let title = '-'
                            if(row.titolo !== undefined) {
                                title = row.titolo.nome
                                info.id_titolo = row.titolo.id
                            }
                            if(row.capo !== undefined && row.capo.titolo !== undefined) {
                                title = row.capo.titolo.nome
                                info.id_titolo = row.capo.titolo.id
                            }
                            if(row.articolo !== undefined && row.articolo.titolo !== undefined) {
                                title = row.articolo.titolo.nome
                                info.id_titolo = row.articolo.titolo.id
                            }
                            if(row.articolo !== undefined && row.articolo.capo !== undefined && row.articolo.capo.titolo !== undefined) {
                                title = row.articolo.capo.titolo.nome
                                info.id_titolo = row.articolo.capo.titolo.id
                            }

                            return(
                                <TableRow>
                                    <TableCell align='center'>{title}</TableCell>
                                    <TableCell align='center'>{head}</TableCell>
                                    <TableCell align='center'>{article}</TableCell>
                                    <TableCell align='center'>{paragraph}</TableCell>
                                    <TableCell align='right'>
                                        <IconButton
                                            onClick={() => handleCreateAssessment(row.id, info)}
                                            disabled={row.assessment === 1 || row.assessment === true}
                                        >
                                            <AddBoxIcon
                                                sx={{ color: (row.assessment === 1 || row.assessment === true) ? 'grey' : 'green' }}
                                            />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }

                    {emptyRows > 0 && 
                        <TableRow style={{ height: 46.6 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    }

                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={props.rowsPerPageOptions}
                            colSpan={3}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}

function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <LastPageIcon />
        </IconButton>
      </Box>
    );
}

Array.prototype.discardNewRow = function () {
    if (this.at( this.length -1 ).id === 0) this.pop()
}